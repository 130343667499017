<app-navbar></app-navbar>

<div class="container-fluid mt-5 pt-4 m-0 p-0 bg" style="width: 100%;">
    <div style="background: rgba(0,0,0,0.5);" id="bannerup">
        <p class="text-white pt-3 pb-3 pl-md-5 pl-2" style="background: rgba(0,0,0,0.5); opacity: 0.7;"><a class="text-white" routerLink="/"> Home </a> / Media</p>
        <h2 id="heading" class="text-white">Media</h2>
    </div>
</div>
<!-- Add new media section Api  -->
<!-- <div class="container">
  <div class="row" *ngFor="let mediaObj of mediaList; let i=index">
    <img [src]="mediaObj.img" alt="" width="100%" height="650">
    <div class="carousel-caption text-dark">
      <h1 class="pt-4 card-title">{{mediaObj.title}}</h1>
      <p class="pl-5 pr-5 card-desc">{{mediaObj.description}} </p> -
    </div>
  </div>
  </div> -->
<!-- End new media section api -->
<!-- section first media -->
<!-- <section id="media1">
    <div class="container">
        <div class="card-deck">
            <div class="card">
                <img class="card-img-top" src="assets/img/esteban.jpg" alt="Card image cap">

            </div>
            <div class="card">
                <img class="card-img-top" src="assets/img/victoria.jpg" alt="Card image cap">

            </div>
            <div class="card">
                <img class="card-img-top" src="assets/img/evara.jpg" alt="Card image cap">

            </div>
        </div>
    </div>
</section> -->
<!-- section first media End -->
<section id="media1">
    <div class="container">
        <div class="col-md-12">
            <h4 class="text-center" style="font-family: 'didot';">Gallery</h4>
        </div>
        <div class="row" id="row1">
                <div class="col-md-4" *ngFor="let mediaObj of mediaList;let i=index">
                    <div class="card border-0">
                        <img [src]="mediaObj.img"  alt="Los Angeles" width="100%" height="650">
                    </div>
                </div>
              
            
            <!-- <div class="col-md-4">
                <div class="card border-0">
                    <img class="card-img-top" src="assets/img/gallery2.jpg" width="100%" alt="Card image">

                </div>
            </div>
            <div class="col-md-4">
                <div class="card border-0">
                    <img class="card-img-top" src="assets/img/gallery3.jpg" width="100%" alt="Card image">

                </div>
            </div>  -->
            <!--
            <div class="loadbut" style="text-align: center;" >
                <button class="btn btn-default" *ngIf="show" (click)="show = !show">LOAD MORE IMAGES</button>
            </div>-->
        </div>
        
       <!--  <div class="row mt-5" id="row1">
            <div class="col-md-4">
                <div class="card border-0">
                    <img class="card-img-top" src="assets/img/gallery2.jpg" width="100%" alt="Card image">
                </div>
            </div>
            <div class="col-md-4">
                <div class="card border-0">
                    <img class="card-img-top" src="assets/img/gallery4.jpg" width="100%" alt="Card image">
                </div>
            </div>
            <div class="col-md-4">
                <div class="card border-0">
                    <img class="card-img-top" src="assets/img/gallery2.jpg" width="100%" alt="Card image">
                </div>
            </div>
        </div> -->

        <div class="loadbut" style="text-align: center;" >
            <button class="btn btn-default" *ngIf="show" (click)="callNext()" >LOAD MORE IMAGES</button>
        </div>
<!-- 
        <div *ngIf="!show" class="row mt-5" id="row1">
            <div class="col-md-4">
                <div class="card border-0">
                    <img class="card-img-top" src="assets/img/gallery2.jpg" width="100%" alt="Card image">

                </div>
            </div>
            <div class="col-md-4">
                <div class="card border-0">
                    <img class="card-img-top" src="assets/img/gallery4.jpg" width="100%" alt="Card image">

                </div>
            </div>
            <div class="col-md-4">
                <div class="card border-0">
                    <img class="card-img-top" src="assets/img/gallery2.jpg" width="100%" alt="Card image">
                </div>
            </div>
        </div> -->

        <!-- bitton for load more images -->
        <!-- <div class="loadbut" style="text-align: center;" >
    <button class="btn btn-default" (click)="show = !show">LOAD MORE IMAGES</button>
  </div> -->
        <!-- btn end -->
        <div class="row mt-5">
            <div class="col-md-12 text-center">
                <h4 style="font-family: 'didot';">Our Story</h4>
            </div>
            <div class="col-md-4 mt-4">
                <div class="card border-0" style="background: #F8F8F8;">
                    <img class="card-img-top" src="assets/img/victoria.jpg" width="100%" alt="Card image">
                </div>
                <div class="card-block px-0 pt-2">
                    <div class="col-sm-2" style="float: left;"> <img src="assets/img/logog.png"></div>
                    <div class="col-sm-10" style="float: right;">
                        <p class="card-text" style="font-family: Regular 20px/24px Verdana;"> Evara Phase 1 and 2
                            | Avalon Realty with Nyati Group - Pune</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-4">
                <div class="card border-0" style="background: #F8F8F8;">
                    <img class="card-img-top" src="assets/img/victoria.jpg" width="100%" alt="Card image">
                </div>
                <div class="card-block px-0 pt-2">
                    <div class="col-sm-2" style="float: left;"> <img src="assets/img/logog.png"></div>
                    <div class="col-sm-10" style="float: right;">
                        <p class="card-text" style="font-family: Regular 20px/24px Verdana;"> Evara Phase 1 and 2
                            | Avalon Realty with Nyati Group - Pune</p>
                    </div>
                </div>
            </div>
            <div class="col-md-4 mt-4">
                <div class="card border-0" style="background: #F8F8F8;">
                    <img class="card-img-top" src="assets/img/victoria.jpg" width="100%" alt="Card image">

                </div>
                <div class="card-block px-0 pt-2">
                    <div class="col-sm-2 " style="float: left;"> <img src="assets/img/logog.png"></div>
                    <div class="col-sm-10" style="float: right;">
                        <p class="card-text" style="font-family: Regular 20px/24px Verdana;"> Evara Phase 1 and 2
                            | Avalon Realty with Nyati Group - Pune</p>
                    </div>
                </div>
            </div>

        </div>

    </div>
</section>

<app-footer></app-footer>
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-avalon-beauty',
  templateUrl: './avalon-beauty.component.html',
  styleUrls: ['./avalon-beauty.component.css']
})
export class AvalonBeautyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

<app-navbar></app-navbar>

<div class="container-fluid mt-5 pt-4 m-0 p-0 bg" style="width: 100%;">
    <div style="background: rgba(0,0,0,0.5);" id="bannerup">
        <p class="text-white pt-3 pb-3 pl-md-5 pl-2" style="background: rgba(0,0,0,0.5); opacity: 0.7;"><a class="text-white" routerLink="/"> Home </a> / Career / Life@Avalon</p>
        <h2 id="heading" class="text-white" >Life@Avalon</h2>
    </div>
</div>

<section class="life_at_pan" id="life1">
  <div class="container">
    <!-- Added new content for life At avalon -->
    <div class="row">
      <div class="col-md-12">
        <h4 class="font-weight-bold">LIFE AT AVALON</h4>
        <p class="text-justify lifeavalon">
          Our clients are our most worthy possessions. Avalon developers make it a point to match up to the expectations of our premium clients who have entrusted us with the mammoth task of fulfilling their dreams and aspiration when it comes to buying a property or building a home or workspace. We are committed to decipher the needs of our clients, deliver the best in our varied areas of operation like site identification, land acquisition, land development, land leasing or construction for both commercial and residential projects. Avalon developers can only live up to its promise of offering the best when the potential of our associates are used to the optimum level. 
        </p>
        <p class="text-justify">Our organizational structure is built primarily around our associates. Avalon Developers make a constant effort to offer the best possible working atmosphere, an inclusive culture and varied opportunities for our employees. This serves as a driving force for them and inspires them to perform their duties to the best of the abilities thereby helping our company to stay true to the level of commitment offered by us. <strong>We are system and process driven, tech driven and are known for being transparent in our work. We set up an environment that enables intrinsic motivation to take over ensuring a high quality performance.</strong>
        </p>
      </div>
    </div>
    <!-- End of life section -->

    <!-- carrier at Avalon section start-->
   <!--  <div class="row">
      <div class="col-md-12">
        <h4>CAREER AT AVALON</h4>
        <p class="text-hustify lifeavalon">Our employees are our precious assets. At Avalon, our associates are tasked with a multitude of everyday responsibilities related to site identification, land acquisition, land development, land leasing or construction.  They have to stay updated about the real estate regulations and trends and have to constantly respond to the changing needs of the clients. We, at Avalon, believe in the philosophy of constant learning in pursuit of growth and progress. We conduct regular training sessions to upgrade the job skills and enhance the knowledge of our employees in order for them to evolve with changing times and strive to be better with each passing day. Our educational programmes aim to specialise our employees in their craft and turn them into experienced professionals who consequently lead to the growth of the company. We want our associates to learn, work and evolve and also help them to maintain a healthy work-life balance.
        </p>
      </div>
    </div> -->
    <!-- End of carrier section -->

    
   <!--  <div class="row">
     
      <div class="col-md-12">
              <p class="font-weight-bold">We Believe In</p>
     <p class="text-justify">Excellence is at the core of what we do. As an organization, we set benchmarks and then compete against them. This continual endeavor to create benchmarks and excel is driven by a set of beliefs that we practice and exhibit while conducting business.</p>
      </div>
    </div> -->
<!-- 
    <div class="row overlapping_row">
      <div class="col-md-3 col-sm-6 empowerment">
         <img src="assets/img/life_1.png" class="overlapping_img">
         <p class="empowerment_heading">Empowerment</p>
         <p class="empowerment_text">To take ownership of business and be exemplar for the industry and self</p>
      </div>
      <div class="col-md-3 col-sm-6 empowerment">
         <img src="assets/img/life_2.png" class="overlapping_img">
         <p class="empowerment_heading">Creativity</p>
         <p class="empowerment_text">Solution provider with focus on creating exemplary value for the stakeholders</p>
      </div>
      <div class="col-md-3 col-sm-6 empowerment">
         <img src="assets/img/life_3.png" class="overlapping_img">
         <p class="empowerment_heading">Agility</p>
         <p class="empowerment_text">Responsive and adaptable to changing business landscape</p>
      </div>
      <div class="col-md-3 col-sm-6 empowerment">
         <img src="assets/img/life_4.png" class="overlapping_img">
         <p class="empowerment_heading">Reliability</p>
         <p class="empowerment_text">Consistently driving relationships to foster Trust with stakeholders</p>
      </div>
    </div> -->
<!-- 
        <div class="row">
          <div class="col-md-12">
          <p class="font-weight-bold">We Expect</p>
          <p class="text-justify">We believe that our associates are the biggest assets who have shaped the organization to be a leader in every way. We expect each associate to deliver the below:</p>
          </div>
        </div> -->
<!-- 
        <div class="row">
            <div class="col-md-3 col-sm-6 we_expect">
                <img src="assets/img/life_5.png" class="img-responsive">
                <p class="font-weight-bold">Leadership</p>
            </div>
            <div class="col-md-3 col-sm-6 we_expect">
                <img src="assets/img/life_6.png" class="img-responsive">
                <p class="font-weight-bold">Diverse Skillset</p>
            </div>
            <div class="col-md-3 col-sm-6 we_expect">
                <img src="assets/img/life_7.png" class="img-responsive">
                <p class="font-weight-bold">Center of Excellence</p>
            </div>
            <div class="col-md-3 col-sm-6 we_expect">
                <img src="assets/img/life_8.png" class="img-responsive">
                <p class="font-weight-bold">Quality Driven</p>
            </div>
        </div> -->

          <!-- <div class="row work_life">
            <div class="col-md-12">
            
            <p class="font-weight-bold">Work-Life @ Avalon</p>
            <p class="text-justify">We are not a traditional developer firm, but an organization that delivers solutions to our stakeholders who have entrusted us with the responsibility of fulfilling aspirations and managing their assets. It is expected that our associates will deliver these expectations, and thus it becomes our priority to keep the associates in the center, while we build the organizational architecture around them. </p>

            <p class="text-justify">The constant endeavor of Human Resources department at Panchshil is to provide opportunities to the associates so that they live to their full potential and deliver the best.  Our vision is to drive Panchshil into a “Great Place to Work” by creating an inclusive culture for its associates. </p>

            <div class="opportunities">
              <p class="font-weight-bold">1. Opportunities for Career Growth</p>

              <p class="text-justify">At Avalon, we believe that learning is the key to progress. Through regular training sessions, we empower our associates with knowledge and job skills. Avalon has taken many initiatives to enhance its employees’ professional aptitude, such as sponsorship of Educational programs - Post Graduate Diploma in Business Management as well as developing a specialized training module - Management Development Program, to encourage leadership amongst experienced professionals. </p>

              <p class="font-weight-bold">2. Avalon Care</p>

              <p class="text-justify">Having a sound mind and body is the key to productivity. To take care of our associates and to support them, we have devised a special “Avalon CARE” programme. Under this programme, the company extends Group Medical insurance, Group Personal Accident insurance and Parents’ Mediclaim Insurance Policy for driving a sense of security amongst the associates. Regular Yoga, nutrition and wellness sessions are organized, to promote a healthy lifestyle amongst them.  We encourage our employees to participate in social causes as well, through regular donation drives.</p>

              <p class="font-weight-bold">3. Avalon Spirit</p>

              <p class="text-justify">We want our associates to “Live. Play. Rise”. We take our celebrations seriously and help our associates maintain a good work-life balance. Fun-filled events and competitions during festivals spark up the essence of enjoying life to its fullest. Sports is an integral part at Avalon. With 16 Cricket teams in the organization, Avalon Premier League is one of the most coveted tournaments. Whether it is Women’s Day or Holi or Diwali, each of these festivals is marked by grand celebrations at Avalon, where everyone gets together to let their hair down and have a good time. </p>
            </div>
            </div>
        </div> -->
  </div>
</section>

<app-footer></app-footer>

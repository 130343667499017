<app-navbar></app-navbar>

<div class="container-fluid mt-md-5 pt-md-4 m-0 p-0 bg" style="width: 100%;">
    <div style="background: rgba(0,0,0,0.5);" id="bannerup">
        <p class="text-white pt-3 pb-3 pl-md-5 pl-2" style="background: rgba(0,0,0,0.5); opacity: 0.7;"><a class="text-white" routerLink="/"> Home </a> / Blog</p>
        <h2 id="heading" class="text-white">Blogs</h2>
    </div>
</div>

<div class="container-fluid inner-5x innerTB" id="blogc">
    <div class="container pr-0">
        <div *ngFor="let blog of blogsList" class="row inner-5x innerB">
            <div class="logo-content innerAll-more">
                <div class="imgbg" [style.background]="'url(' + getImageUrl(blog.featured_image, blog.folder) + ')'"
                    style="background-size: cover;background-repeat: no-repeat;background-position: center;">
                </div>
            </div>
            <div class="desc-content innerAll-40">
                <span class="title">
                    {{blog.title}}
                </span>
                <br /><br />
                <p>{{blog.created_at | date:'dd MMM'}}</p>
                <br /><a [routerLink]="['/blogdetail',blog.id]" class="hvr-bounce-to-right">View Details</a>
            </div>
        </div>

    </div>
</div>



<app-footer></app-footer>
<app-navbar></app-navbar>

<div class="container-fluid mt-5 pt-4 m-0 p-0 bg" style="width: 100%;">
    <div style="background: rgba(0,0,0,0.5);" id="bannerup">
        <p class="text-white pt-3 pb-3 pl-md-5 pl-2" style="background: rgba(0,0,0,0.5); opacity: 0.7;"><a class="text-white" routerLink="/"> Home </a> / About Us / Corporate Profile / Our Roadmap</p>
        <h2 id="heading" class="text-white" >Our Roadmap</h2>
    </div>
</div>
<section class="chronicle-section" id="road">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <h2 class="text-center didot-font" id="head1">As We Grew</h2>
        <br/>
      </div>
    </div>

    <ul class="timeline" style="list-style: none !important;">
              <li class = "">
          <div class="timeline-badge primary"><a class="timeline-icon"><img src="assets/img/growth-dot.png" alt="growth dot"></a></div>
          <div class="timeline-panel">
            <div class="timeline-heading">
                         <img class="img-responsive" alt="<ul><li>Launch of The Ritz-Carlton Pune.</li></ul>" src="assets/img/evara.jpg" style="width: 100%;" />
                         </div>
            <div class="timeline-body">
              <h2><i class="far fa-calendar-check" aria-hidden="true" id="font1"></i>2019</h2>              
           
              <ul><li>Launch of The Ritz-Carlton Pune.</li></ul>            </div>
          </div>
        </li>
                <li class = "timeline-inverted">
          <div class="timeline-badge primary"><a class="timeline-icon"><img src="assets/img/growth-dot.png" alt="growth dot"></a></div>
          <div class="timeline-panel">
            <div class="timeline-heading">
                         <img class="img-responsive" alt="<ul><li>Launch of Tower B, Trump Towers Pune.</li><li>Launch of YOO Villas, styled by Kelly Hoppen.</li></ul>" src="assets/img/esteban.jpg" style="width: 100%;" />
                         </div>
            <div class="timeline-body">
              <h2><i class="far fa-calendar-check" aria-hidden="true" id="font1"></i>2018</h2>              
           
              <ul><li>Launch of Tower B, Trump Towers Pune.</li><li>Launch of YOO Villas, styled by Kelly Hoppen.</li></ul>            </div>
          </div>
        </li>
                <li class = "">
          <div class="timeline-badge primary"><a class="timeline-icon"><img src="assets/img/growth-dot.png" alt="growth dot"></a></div>
          <div class="timeline-panel">
            <div class="timeline-heading">
                         <img class="img-responsive" alt="<ul><li>Acquisition of First International Financial Centre (FIFC), BKC, Mumbai with Blackstone.</li><li>“The Pavillion” mall goes operational.</li><li>Construction of Panchshil Business Park – Phase II commences.</li><li>Forays into Mumbai with the launch of luxury residences “Ocean Towers”.</li></ul>" src="assets/img/erica.jpg" style="width: 100%;" />
                         </div>
            <div class="timeline-body">
              <h2><i class="far fa-calendar-check" aria-hidden="true" id="font1"></i> 2018</h2>              
           
              <ul><li>Launch of The Ritz-Carlton Pune.</li><!-- <li>“The Pavillion” mall goes operational.</li><li>Construction of Panchshil Business Park – Phase II commences.</li><li>Forays into Mumbai with the launch of luxury residences “Ocean Towers”.</li> --></ul>            </div>
          </div>
        </li>
                <li class = "timeline-inverted">
          <div class="timeline-badge primary"><a class="timeline-icon"><img src="assets/img/growth-dot.png" alt="growth dot"></a></div>
          <div class="timeline-panel">
            <div class="timeline-heading">
                         <img class="img-responsive" alt="<ul><li>Oakwood Premier re-branded to Marriott Suites.</li><li>Construction of EON Free Zone - Phase II commences.</li><li>Construction of Futura commences.</li></ul>" src="assets/img/victoria.jpg" style="width: 100%;" />
                         </div>
            <div class="timeline-body">
              <h2><i class="far fa-calendar-check" aria-hidden="true" id="font1"></i>2017-18</h2>              
           
              <ul><li>Launch of Tower B, Trump Towers Pune.</li><li>Launch of YOO Villas, styled by Kelly Hoppen.</li></ul>            </div>
          </div>
        </li>

         <li class = "">
          <div class="timeline-badge primary"><a class="timeline-icon"><img src="assets/img/growth-dot.png" alt="growth dot"></a></div>
          <div class="timeline-panel">
            <div class="timeline-heading">
                                    </div>
            <div class="timeline-body">
              <h2><i class="far fa-calendar-check" aria-hidden="true" id="font1"></i>2017</h2>              
           
              <ul><li>Establish Avalon Realty in 2017</li><li>Panchshil signs an MOU with the Government of Maharashtra to develop 4 IT projects jointly with Blackstone, across Mumbai and Pune.</li></ul>            </div>
          </div>
        </li>
        <li class="clearfix" style="float: none;"></li>
    </ul>
  </div>
  </section>    
  
<app-footer></app-footer>
  
<app-navbar></app-navbar>

<div class="container-fluid mt-5 pt-4 m-0 p-0 bg" style="width: 100%;">
    <div style="background: rgba(0,0,0,0.5);" id="bannerup">
        <p class="text-white pt-3 pb-3 pl-md-5 pl-2" style="background: rgba(0,0,0,0.5); opacity: 0.7;"><a class="text-white" routerLink="/"> Home </a> / Health & Safety</p>
        <h2 id="heading" class="text-white" >Quality, Environment, Health and Safety</h2>
    </div>
</div>


<section id="health">

<div class="row  text-center">
    <div class="col-12 mb-3">
        <h3 class="text-uppercase">Park Amenities</h3>
        <hr style="width: 8%;border-bottom: 2px solid #f19f22;"> 
    </div>

    <div class="col-md-3 col-12 main mb-3">
        <div class="style mb-3">
            <i class="fas fa-car" style="font-size: 30px;"></i>
        </div>
        <h6>AMPLE FREE PARKING</h6>
        <p style="color: #999;">Ample free parking for vehicles of all <br> sizes and shapes.</p>
    </div>
    <div class="col-md-3 col-12 main  mb-3">
        <div class="style mb-3">
            <i class="fas fa-lock" style="font-size: 30px;"></i>
        </div>
        <h6>LOCKER BAG RENTAL</h6>
        <p style="color: #999;">Locker bags to keep all your belongings <br> and precious items safe.</p>
    </div>
    <div class="col-md-3 col-12 main  mb-3">
        <div class="style mb-3">
            <i class="fas fa-swimmer" style="font-size: 30px;"></i>
        </div>
        <h6>SWIMMING COSTUME RENTAL</h6>
        <p style="color: #999;">Not sure if your swim wear still fits ? Worry not we've got you covered, literally!</p>
    </div>
    <div class="col-md-3 col-12 main  mb-3">
        <div class="style mb-3">
            <i class="fas fa-store" style="font-size: 30px;"></i>
        </div>
        <h6>MULTIPURPOSE IN-HOUSE STORE</h6>
        <p style="color: #999;">From refreshments to Sun-block we've got <br> a fully equipped store.</p>
    </div>
    <div class="col-md-3 col-12 main  mb-3">
        <div class="style mb-3">
            <i class="fas fa-utensils" style="font-size: 30px;"></i>
        </div>
        <h6>THE HILL TOP RESTAURANT</h6>
        <p style="color: #999;">You get a hot Veg Lunch at the Hill-Top Restaurant <br> located centrally at Diamond Water Park.</p>
    </div>
    <div class="col-md-3 col-12 main  mb-3">
        <div class="style mb-3">
            <i class="fas fa-hamburger" style="font-size: 30px;"></i>
        </div>
        <h6>TIKKI CAFE</h6>
        <p style="color: #999;">Time for snacking! Get fast food items at newly opened Tikki Café.</p>
    </div>
    <div class="col-md-3 col-12 main  mb-3">
        <div class="style mb-3">
            <i class="fas fa-toilet-paper" style="font-size: 30px;"></i>
        </div>
        <h6>WASHROOMS</h6>
        <p style="color: #999;">We have separate washrooms for men and women at three different locations in the park.</p>
    </div>
    <div class="col-md-3 col-12 main  mb-3">
        <div class="style mb-3">
            <i class="fas fa-restroom" style="font-size: 30px;"></i>
        </div>
        <h6>CHANGING ROOMS</h6>
        <p style="color: #999;">We have separate changing rooms for men and <br> women at three different locations in the park.</p>
    </div>

</div> 

<div class="container-fluid cbg pb-4">
    <div class="col-12 mb-3 text-center">
        <h3 class="text-uppercase pt-5">Diamond Parks, Perfect Destination for Events</h3>
        <hr style="width: 8%;border-bottom: 2px solid #f19f22;">
    </div>

    <div class="container">
        <div class="row mb-3 mt-4">
            <div class="col-md-3 col-12 mb-3">
                <div class="card" style="border-radius: 10px;min-height: 500px;">
                    <img src="assets/img/image-988.jpg" class="card-img-top" alt="...">
                    <div class="card-body text-center">
                        <h5>Your Dream Venue</h5>
                      <p class="card-text">With a capacity of over 4500 pax Diamond Parks is your only destination for any event from birthday parties to weddings and everything in between.</p>
                    </div>
                  </div>
            </div>
            <div class="col-md-3 col-12 mb-3">
                <div class="card" style="border-radius: 10px;min-height: 500px;">
                    <img src="assets/img/image-b42.jpg" class="card-img-top" alt="...">
                    <div class="card-body text-center">
                        <h5>Sing and dance with us</h5>
                      <p class="card-text">Host your next music concert, award ceremony, movie promotion or album launch.</p>
                    </div>
                  </div>
            </div>
            <div class="col-md-3 col-12 mb-3">
                <div class="card" style="border-radius: 10px;min-height: 500px;">
                    <img src="assets/img/image-eef.jpg" class="card-img-top" alt="...">
                    <div class="card-body text-center">
                        <h5>Graduate with fun</h5>
                      <p class="card-text">Celebrate your annual college function ,talent show or your graduation party at Diamond Parks</p>
                    </div>
                  </div>
            </div>    
    
            <div class="col-md-3 col-12 mb-3">
                <div class="card" style="border-radius: 10px;min-height: 500px;">
                    <img src="assets/img/image-783.jpg" class="card-img-top" alt="...">
                    <div class="card-body text-center">
                        <h5>Your Destination Wedding</h5>
                      <p class="card-text">Get the full luxurious experience of a destination wedding without moving out of the city.</p>
                    </div>
                  </div>
            </div>
            <div class="col-md-3 col-12 mb-3">
                <div class="card" style="border-radius: 10px;min-height: 500px;">
                    <img src="assets/img/image-462.jpg" class="card-img-top" alt="...">
                    <div class="card-body text-center">
                        <h5>Your D-Day at Diamond</h5>
                      <p class="card-text">Our Wedding team handles everything from decorations to the catering to make sure you get to focus on your very special day.</p>
                    </div>
                  </div>
            </div>
            <div class="col-md-3 col-12 mb-3">
                <div class="card" style="border-radius: 10px;min-height: 500px;">
                    <img src="assets/img/image-56b.jpg" class="card-img-top" alt="...">
                    <div class="card-body text-center">
                        <h5>Over 7 magnificent Lawns</h5>
                      <p class="card-text">Pick from over 7 grand lawns to celebrate your special day.</p>
                    </div>
                  </div>
            </div>
            <div class="col-md-3 col-12 mb-3">
                <div class="card" style="border-radius: 10px;min-height: 500px;">
                    <img src="assets/img/image-00b.jpg" class="card-img-top" alt="...">
                    <div class="card-body text-center">
                        <h5>The Best Photos, ever!</h5>
                      <p class="card-text">Photos with great scenic backgrounds, vibrant colors and a brilliant sunset.</p>
                    </div>
                  </div>
            </div>
            <div class="col-md-3 col-12 mb-3">
                <div class="card" style="border-radius: 10px;min-height: 500px;">
                    <img src="assets/img/image-b8b.jpg" class="card-img-top" alt="...">
                    <div class="card-body text-center">
                        <h5>One venue, multiple options.</h5>
                      <p class="card-text">We've hosted movie launches, office parties, large family gatherings, kitty parties among other events Come experience a venue with infinite possibilities.</p>
                    </div>
                  </div>
            </div>
        </div>
    </div>

</div>
    
<div class="row mt-4" id="row1">
    <div class="col-12 mb-3 text-center">
        <h3 class="text-uppercase">Health & Safety</h3>
        <hr style="width: 8%;border-bottom: 2px solid #f19f22;">
        <p class="text-center">You're in safe hands at Diamond Water Park with our unrivalled reputation for customer safety. We take multiple precautions to ensure a fun, safe water park experience for all visitors.</p>
    </div>

        <div class="col-md-6">
            <div class="card flex-row  border-0" style="background: #F8F8F8;">
        <div class="card-header border-0 ">
            <img src="assets/img/lifeguards.png" alt=""  >
        </div>
        <div class="card-block px-md-5 p-3 pt-4" >
            <h5 class="card-title text-uppercase" style="font-family: Verdana;">Lifeguards</h5>
            <p  >Every attraction in the Waterpark is staffed by certified lifeguards, who ensure that guests can enjoy rides in a timely fashion while staying safe in the water.</p>
            <br>
        </div>
    </div>
        </div>
        <div class="col-md-6">
            <div class="card flex-row  border-0" style="background: #F8F8F8;">
        <div class="card-header border-0 ">
            <img src="assets/img/firstaid.png" alt="" >
        </div>
        <div class="card-block px-md-5 p-3 pt-4 " >
            <h5 class="card-title text-uppercase" style="font-family: Verdana;">First-Aid Kits</h5>
            <p  >All attractions in the Waterpark is equipped with fully functional and updated First-Aid kits, additionally a full kit is available at the Waterpark Store. </p>
            <br>
        </div>
    </div>
        </div>
        
        
   </div>

   <div class="row mt-5" id="row1">
        <div class="col-md-6 ">
            <div class="card flex-row  border-0" style="background: #F8F8F8;">
        <div class="card-header border-0 ">
            <img src="assets/img/maintainance.png" alt="" >
        </div>
        <div class="card-block px-md-5 p-3 pt-4 " >
            <h5 class="card-title text-uppercase" style="font-family: Verdana;">Maintenance</h5>
            <p  >Regular Checks and Full Maintenance is carried out every day at Diamond Waterpark to ensure you have a safe experience.  </p>
            <br>
        </div>
    </div>
        </div>
        <div class="col-md-6">
            <div class="card flex-row  border-0" style="background: #F8F8F8;">
        <div class="card-header border-0">
            <img src="assets/img/ambulance.png" alt="" >
        </div>
        <div class="card-block px-md-5 p-3 pt-4 " >
            <h5 class="card-title text-uppercase" style="font-family: Verdana;">Ambulance Services</h5>
            <p>Immediate Ambulance facility is available on-call all day long at the Waterpark. We've had a zero incident day since we opened for almost 25 years.  </p>
            <br>
        </div>
    </div>
        </div>        
   </div>

   <div class="container-fluid mt-5">
    <div class="col-12 mb-4 mt-4 text-center">
        <h3 class="text-uppercase">Adventure Park Safety</h3>
        <hr style="width: 8%;border-bottom: 2px solid #f19f22;">
    </div>

    <div class="row">
        <div class="col-lg-6 col-12 leftbg">
            <div class="card flex-row  border-0 mt-5 ml-4 mr-4" style="background: #F8F8F8;border-radius: 10px;">
                <div class="card-header border-0" style="overflow: hidden;">
                    <img src="assets/img/image-c47.jpg" alt="" class="img-fluid zoom" style="border-top-left-radius: 10px;border-bottom-left-radius: 10px;width: 300px;">
                </div>
                <div class="card-block px-5 pt-4 " >
                    <h5 class="card-title text-uppercase" style="font-family: Verdana;">Park Rangers</h5>
                    <p>Mountaineering experts who have scaled the Himalayan Mountain Ranges and certified in safety procedures and emergency situations.</p>
                    <br>
                </div>
            </div>

            <div class="card flex-row  border-0 mt-3  ml-4 mr-4" style="background: #F8F8F8;border-radius: 10px;">
                <div class="card-header border-0" style="overflow: hidden;width: 246px;">
                    <img src="assets/img/image-da8.jpg" alt="" class="img-fluid zoom" style="border-top-left-radius: 10px;border-bottom-left-radius: 10px;width: 100%;">
                </div>
                <div class="card-block px-5 pt-4 " >
                    <h5 class="card-title text-uppercase" style="font-family: Verdana;">Park Marshals</h5>
                    <p>Knowledgeable and Experienced mountaineers that are very well aware of safety procedures.</p>
                    <br>
                </div>
            </div>

            <div class="card flex-row  border-0 mt-3 mb-3  ml-4 mr-4" style="background: #F8F8F8;border-radius: 10px;">
                <div class="card-header header-width border-0" style="overflow: hidden;width: 382px;">
                    <img src="assets/img/image-069.jpg" alt="" class="img-fluid zoom" style="border-top-left-radius: 10px;border-bottom-left-radius: 10px;width: 100%;">
                </div>
                <div class="card-block px-5 pt-4 " >
                    <h5 class="card-title text-uppercase" style="font-family: Verdana;">ERCA Certified</h5>
                    <p>Diamond Adventure Park adheres to all the existing ERCA (European Rope Course Association) standards in construction, inspection & operation.</p>
                    <br>
                </div>
            </div>
        </div>
        <div class="col-lg-6 col-12 rightbg" style="height: 720px;">

        </div>
    </div>
</div>
</section>   

<app-footer></app-footer>

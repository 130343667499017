<app-navbar></app-navbar>
<div class="container-fluid mt-5 pt-4 m-0 p-0 bg" style="width: 100%;">
    <div style="background: rgba(0,0,0,0.2);height: 100%;">
        <p class="text-white pt-3 pb-3 pl-md-5 pl-2" style="background: rgba(0,0,0,0.5); opacity: 0.7;"><a routerLink="/" class="text-white">Home</a> / Desclaimer</p>
        <h2 class="text-white" style="text-align: center;vertical-align: middle;line-height: 30vh; font: Bold 35px/186px Didot LT Std;">Desclaimer</h2>
    </div>
</div>

<div class="container my-5">
    <p>Avalon Developers may without prior notice and without refund or compensation change the operating hours of the park or attractions; close the Park or any part of it temporarily; restrict the number of persons having access to the park; suspend or cancel any attraction or entertainment program due to capacity constraints, inclement weather or special events to ensure safety, security and order if we consider that the circumstances so require.</p>
    <p>Avalon Developers reserves the right to refuse admission to a guest if in the reasonable opinion of the staff, the presence of the guest may pose a risk to the safety of other guests or affect the enjoyment of others within the facility or affect the normal operations of the DIAMOND WATER PARK facility.</p>
    <p>Avalon Developers reserves the right to require a person already admitted to leave the facility without refund or compensation for failure to comply with any of the facility rules, for unsafe, illegal or offensive behaviour and to ensure safety, security and order, or if we consider that the circumstances so require.</p>
    <p>Avalon Developers reserves the right to require a person in the outer premises (Parking Lot, Security Buffer Zone, Ticketing Area, or directly outside of the DIAMOND WATER PARK facility) to remove himself for failure to comply with any of the DIAMOND WATER PARK facility rules, for unsafe, illegal or offensive behaviour, to ensure safety, security and order, or if we consider that the circumstances so require. If person(s) do not relocate voluntary, DIAMOND WATER PARK will contact local authorities to have person(s) removed on grounds of trespassing or a threat to the security of DIAMOND WATER PARK operations.</p>
    <p>Avalon Developers reserves the right to introduce differential pricing during both normal and peak days for different customer groups. This includes but is not limited to groups, schools, complimentary guests, special interest groups, special needs guests, elderly guests or special offers and discounts.</p>
    <p>Avalon Developers reserves the right to refuse or deny any special discount offer. In any way or form in accordance with the terms and conditions that were posted when the special offer was posted.</p>
    <p>Avalon Developers is not responsible and does not assume any liability for any damage to, loss of, disappearance of, theft of, or any other form of alteration, physical or otherwise to personal property of any guest, whether such damage or loss is caused by our negligence or otherwise. The use of our facilities is at your own risk.</p>
    <p>Avalon Developers not responsible and does not assume any liability for any damage to, death, or any other form of injury, physical, mental or otherwise of any guest, whether such damage or loss is caused by our negligence or otherwise. The use of our facilities is at your own risk.</p>
    <p>Avalon Developers reserves the right to hold liable any guest who has caused any damage to, loss of, disappearance of, theft of, or any other form of alteration, physical or otherwise to DIAMOND WATER PARK property if such event is caused by guest due to gross negligence or non-compliance of the rules and regulations as set forth in this document.</p>
</div>
<app-footer></app-footer>
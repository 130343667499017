
<app-navbar></app-navbar>

<div class="container-fluid mt-5 pt-4 m-0 p-0 bg" style="width: 100%;">
    <div style="background: rgba(0,0,0,0.2);height: 100%;">
        <p class="text-white pt-3 pb-3 pl-md-5 pl-2" style="background: rgba(0,0,0,0.5); opacity: 0.7;"><a routerLink="/" class="text-white">Home</a> / Privacy Policy</p>
        <h2 class="text-white" style="text-align: center;vertical-align: middle;line-height: 30vh; font: Bold 35px/186px Didot LT Std;">Privacy Policy</h2>
    </div>
</div>

<div class="container my-5">
    <p>Avalon Group Pvt Ltd ("Avalon Group Pvt Ltd ") operates <a href="https://avalondevelopers.com/">https://avalondevelopers.com/</a> and may operate other websites. It is Avalon Group Pvt Ltd’s policy to respect your privacy regarding any information we may collect while operating our websites.</p>

    <h4>Website Visitors</h4>
    <p>Like most website operators, Avalon Group Pvt Ltd collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Avalon Group Pvt Ltd’s purpose in collecting non-personally identifying information is to better understand how Avalon Group Pvt Ltd’s visitors use its website. From time to time, Avalon Group Pvt Ltd may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.</p>
    <p>Avalon Group Pvt Ltd also collects potentially personally-identifying information like Internet Protocol (IP) addresses for logged in users and for users leaving comments on <a href="https://avalondevelopers.com/">https://avalondevelopers.com/</a> sites. Avalon Group Pvt Ltd only discloses logged in user and commenter IP addresses under the same circumstances that it uses and discloses personally-identifying information as described below, except that commenter IP addresses and email addresses are visible and disclosed to the administrators of the site where the comment was left.</p>

    <h4>Gathering of Personally-Identifying Information</h4>
    <p>Certain visitors to Avalon Group Pvt Ltd’s websites choose to interact with Avalon Group Pvt Ltd in ways that require Avalon Group Pvt Ltd to gather personally-identifying information. The amount and type of information that Avalon Group Pvt Ltd gathers depends on the nature of the interaction. For example, we ask visitors who sign up at <a href="https://avalondevelopers.com/">https://avalondevelopers.com/</a> to provide a username and email address. Those who engage in transactions with Avalon Group Pvt Ltd are asked to provide additional information, including as necessary the personal and financial information required to process those transactions. In each case, Avalon Group Pvt Ltd collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor's interaction with Avalon Group Pvt Ltd. Avalon Group Pvt Ltd does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain website-related activities.</p>

    <h4>Aggregated Statistics</h4>
    <p>Avalon Group Pvt Ltd may collect statistics about the behavior of visitors to its websites. Avalon Group Pvt Ltd may display this information publicly or provide it to others. However, Avalon Group Pvt Ltd does not disclose personally-identifying information other than as described below.</p>

    <h4>Protection of Certain Personally-Identifying Information</h4>
    <p>Avalon Group Pvt Ltd discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (I) need to know that information in order to process it on Avalon Group Pvt Ltd’s behalf or to provide services available at Avalon Group Pvt Ltd’s websites, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using Avalon Group Pvt Ltd’s websites, you consent to the transfer of such information to them. Avalon Group Pvt Ltd will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Avalon Group Pvt Ltd discloses potentially personally-identifying and personally-identifying information only in response to a subpoena, court order or other governmental request, or when Avalon Group Pvt Ltd believes in good faith that disclosure is reasonably necessary to protect the property or rights of Avalon Group Pvt Ltd, third parties or the public at large. If you are a registered user of an Avalon Group Pvt Ltd website and have supplied your email address, Avalon Group Pvt Ltd may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with Avalon Group Pvt Ltd and our products. If you send us a request (for example via email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Avalon Group Pvt Ltd takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.</p>

    <h4>Cookies</h4>
    <p>A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the website each time the visitor returns. Avalon Group Pvt Ltd uses cookies to help Avalon Group Pvt Ltd identify and track visitors, their usage of Avalon Group Pvt Ltd website, and their website access preferences. Avalon Group Pvt Ltd visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Avalon Group Pvt Ltd’s websites, with the drawback that certain features of Avalon Group Pvt Ltd’s websites may not function properly without the aid of cookies.</p>

    <h4>Business Transfers</h4>
    <p>If Avalon Group Pvt Ltd , or substantially all of its assets, were acquired, or in the unlikely event that Avalon Group Pvt Ltd  goes out of business or enters bankruptcy, user information would be one of the assets that is transferred or acquired by a third party. You acknowledge that such transfers may occur, and that any acquirer of Avalon Group Pvt Ltd may continue to use your personal information as set forth in this policy.</p>

    <h4>Privacy Policy Changes</h4>
    <p>Although most changes are likely to be minor, Avalon Group Pvt Ltd may change its Privacy Policy from time to time, and in Avalon Group Pvt Ltd’s sole discretion. Avalon Group Pvt Ltd encourages visitors to frequently check this page for any changes to its Privacy Policy. If you have a <a href="https://avalondevelopers.com/"> https://avalondevelopers.com/ </a> account, you might also receive an alert informing you of these changes. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.</p>
</div>

<app-footer></app-footer>
<app-navbar></app-navbar>

<!-- <div style="height:10px;"></div> -->
<div class="container-fluid mt-5 pt-4 m-0 p-0 bg">
    <div style="background: rgba(0,0,0,0.5);height: 100%;">
        <p class="text-white pt-3 pb-3 pl-md-5 pl-2" style="background: rgba(0,0,0,0.5); opacity: 0.7;"><a class="text-white" routerLink="/"> Home </a> / About Us / Corporate Profile / Forward</p>
        <h2 class="text-white" style="text-align: center;vertical-align: middle;line-height: 30vh; font: Bold 35px/186px Didot LT Std;">Forward</h2>
    </div>
</div>

  <!-- <div class="banner-hralign" style="background-image: url(https://www.panchshil.com/admin/uploads/corporateImages/58a42176326f1.jpg);height: 300px;margin-top: 120px !important;">
    <div class="container-fluid nopadding">
        <ol class="breadcrumb">
            <li><a href="https://www.panchshil.com/">Home</a></li>
            <li><a href="https://www.panchshil.com/about/">About</a></li>
            <li><a href="https://www.panchshil.com/about/corporate-profile/">Corporate Profile</a></li>
            <li class="active">Foreward</li>
        </ol>
    </div>
    <h1 style="margin-top: 40px;">Foreword</h1> 
</div>  -->


<div class="container mt-5 "> 
<section class="business-section" id="main1">
    <div class="container business-container">
        <div class="row">

                <div class="col-md-3 mb-3">
                    <img class="img-fluid" alt="arjun" src="assets/img/arjun.png"/>
                </div>
                <div class="col-md-9 foreward-text text-left pr-md-5">
                 <!-- <p style="text-align: justify;">We dared to dream big. A dream to build not just homes, but lifestyles. A dream to create integrated workspaces, and to shower hospitality with gilded luxury. Our approach is focused on planned development, creating value assets for the city, and to become the leader in our spheres of business.</p>
                <p style="text-align: justify;">Over the years, we have seen our dreams come true. Our properties are now the biggest landmarks of Pune. We have ushered in not just the best construction practices, but also the world’s best brands to Pune, and in the process changed the city’s skyline.</p>
                <p style="text-align: justify;">The credit of our success goes to our partners, investors, clients and our thousand-strong workforce who makes us one of India’s finest luxury real estate brands. We know the road ahead is long and exciting, and we have many more milestones to establish. However, every milestone reaffirms our commitment to creating value assets for all stakeholders. &nbsp;</p>-->
                <!-- Added new content for Forword -->
                <p class="text-justify overflow-auto overflow-hidden mt-0">Avalon real estate service believes in the power of dreams. Building a home is a life changing experience.  We, at Avalon Developers harbour and nurture the dream of creating the perfect house, property or commercial project you had always <strong>desired.</strong>Inspired by the myriad hopes and aspiration of people and driven by mission, we create <strong>premium quality</strong> spaces that suit your lifestyle, fit your requirements perfectly thereby offering you all the exclusive facilities of modern living.  Creating value assets for the city is our own way of touching lives.
                </p>
                <p class=" text-justify overflow-auto">With planned development and extraordinary constructions, we have been able to revamp the skyline of Pune in the last few years of our journey. Today, our pioneering works in the real estate sector in Pune have received a wide acclaim. Our values truly involve the best interests of our clients. Defined by our sincerity, honesty and services, we offer you the idyllic gateway to a luxurious and comfortable life.  We encourage our clients to expect more from us and in pursuit of giving wings to their dreams, we embrace every challenge that paves our way and rise above.
                </p>
                <p class="text-justify overflow-auto">We dedicate our success to our partners, clients, <strong>elders, well-wishers and investors who have showered us with faith & blessings along the way.</strong> We also honour the immense hard work of our efficient workforce who have helped us to deliver the best and match up to the expectations of our clients. We have a long way to go and many more milestones to achieve. The journey ahead will be exciting and we promise to stand true to our commitment of taking you closer to your dream even in the years to come. 
                </p>
                <h4 class="didot-font mb-0 pb-2 custom-h4">Arjun Indulkar</h4>
                <p class="chairman-A py-0 my-0">Chairman–Avalon</p>
            </div> 

        </div> 
    </div>
</section>
 </div> 

<app-footer></app-footer>



<app-navbar></app-navbar>


<div class="container-fluid mt-5 pt-4 m-0 p-0 bg" style="width: 100%;">
    <div style="background: rgba(0,0,0,0.2);height: 100%;">
        <p class="text-white pt-3 pb-3 pl-md-5 pl-2" style="background: rgba(0,0,0,0.5); opacity: 0.7;"> <a class="text-white" routerLink="/"> Home </a> / Alliances / Partners</p>
        <h2 class="text-white" style="text-align: center;vertical-align: middle;line-height: 30vh; font: Bold 35px/186px Didot LT Std;">Partners</h2>
    </div>
</div>

<!-- <div class="container-fluid inner-5x innerTB" id="partner1">
<div class="container">
  <div class="row inner-5x innerB">
<div class="logo-content innerAll-more"><img alt="" src="assets/img/nyati_logo.png"></div>
<div class="desc-content innerAll-40 brand">
<span class="title">NYATI GROUP</span><br/><br/>
<p  >AT NYATI GROUP, CUSTOMER IS THE CENTRAL FOCUS OF ALL OUR VENTURES. WE PUT AN EARNEST EFFORT IN UNDERSTANDING THE ASPIRATIONS OF OUR BUYERS WITH THE OBJECTIVE OF MEETING ALL THEIR EXPECTATIONS. WE BELIEVE THERE IS NO JOY GREATER THAN DELIVERING CUSTOMER HAPPINESS AND SATISFACTION.</p><br/><a href="" target = "blank" >view detail</a>
</div>
    </div>
<div class="row inner-5x innerB">
<div class="logo-content innerAll-more"><img alt="Blackstone" src="assets/img/nyati_logo.png"></div>
<div class="desc-content innerAll-40 brand">
<span class="title">NYATI GROUP</span><br/><br/>
<p>AT NYATI GROUP, CUSTOMER IS THE CENTRAL FOCUS OF ALL OUR VENTURES. WE PUT AN EARNEST EFFORT IN UNDERSTANDING THE ASPIRATIONS OF OUR BUYERS WITH THE OBJECTIVE OF MEETING ALL THEIR EXPECTATIONS. WE BELIEVE THERE IS NO JOY GREATER THAN DELIVERING CUSTOMER HAPPINESS AND SATISFACTION.</p><br/><a href="" target = "blank" >view detail</a>
</div>
    </div>
 </div>
 </div>   
 -->
 <section id="part">

 <div class="container">
    <div class=" align-middle card flex-row  border-0 " style="background: #F8F8F8;">
        <div class="align-middle card-header border-0 p-5 d-flex align-items-center" style="background: #eeeeee;">
            <img src="assets/img/nyati_logo.png" alt="" id="nayatilogo"class="align-middle card-img m-5" >
        </div>
        <div class="card-block px-md-5 px-3 pt-4 pr-lg-4 mr-lg-4 pb-3" >
            <h4 class="card-title text-capitalize" style="font-family: Verdana;">Nyati group</h4>
            <!-- Added new  content for partners in Alliances-->
            <h4 class="card-title text-capitalize" style="font-family: Verdana;">Nyati Evara Project:</h4>
            <p class="text-justify pl-0">
                Nyati Evara Project is an ideal example of the expertise and skilled craftsmanship of Avalon Developers. Situated at the well connected suburb of NIBM Pune, Nyati Evara presents to you the sublime flavour of green living and boutique lifestyle. Away from the cacophony of the city life and set amidst lush greenery, Nyati Evara is a complete real estate destination offering picturesque views. Equipped with exclusive facilities and amenities at par with international standards, the lavish 2RHK apartments will surely enhance the quality of your life. Developed by Avalon  developers, Nyati Evara Project ensures the much desired combination of elegance, comfort, luxury and serenity to all the residents. Avalon developers have not only delivered the project on time but have also stayed true to its commitment of maintaining high ethical standards throughout. Offering all the facets of an urban lifestyle, Nyati Evara Project reflects the sincerity and customer centricity of Avalon Developers at its best. This premium quality project has been completed by keeping the interests and requirements of the customers in mind. 

            </p>
            
            
            <!-- End of partners section  -->
           <!--  
            <p  >AT NYATI GROUP, CUSTOMER IS THE CENTRAL FOCUS OF ALL OUR VENTURES. WE PUT AN EARNEST EFFORT IN UNDERSTANDING THE ASPIRATIONS OF OUR BUYERS WITH THE OBJECTIVE OF MEETING ALL THEIR EXPECTATIONS. WE BELIEVE THERE IS NO JOY GREATER THAN DELIVERING CUSTOMER HAPPINESS AND SATISFACTION.  </p>
            <br><a href="" target = "blank" >view detail</a> -->
        </div>
    </div>


</div>

<!-- <div class="container mt-5">
    <div class="card flex-row  border-0" style="background: #F8F8F8;">
        <div class="card-header border-0 p-5">
            <img src="assets/img/nyati_logo.png" alt="" class="m-5" >
        </div>
        <div class="card-block px-5 pt-4 pr-4 mr-5" >
            <h4 class="card-title text-uppercase" style="font-family: Verdana;">Nyati group</h4>
            <p  >AT NYATI GROUP, CUSTOMER IS THE CENTRAL FOCUS OF ALL OUR VENTURES. WE PUT AN EARNEST EFFORT IN UNDERSTANDING THE ASPIRATIONS OF OUR BUYERS WITH THE OBJECTIVE OF MEETING ALL THEIR EXPECTATIONS. WE BELIEVE THERE IS NO JOY GREATER THAN DELIVERING CUSTOMER HAPPINESS AND SATISFACTION.  </p>
            <br/><a href="" target = "blank" >view detail</a>
        </div>
    </div>
    
</div> -->
<!-- </section> -->
</section>

<app-footer></app-footer>

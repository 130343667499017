<app-navbar></app-navbar>

<div class="container-fluid mt-5 pt-4 m-0 p-0 bg" style="width: 100%;">
    <div style="background: rgba(0,0,0,0.5);" id="bannerup">
        <p class="text-white pt-3 pb-3 pl-md-5 pl-2" style="background: rgba(0,0,0,0.5); opacity: 0.7;"><a class="text-white" routerLink="/"> Home </a> / Career / Job Opportunity</p>
        <h2 id="heading" class="text-white text-capitalize" >Job Opportunity</h2>
    </div>
</div>


<section id="job1">
<div class="container ">
    <!-- carrier at avalon -->
    <div class=" avaloncarrier pt-4 pb-5">
        <div class="card" style="background: #F8F8F8;">
            <div class="card-header text-capitalize" id="carriercard" style="font-family: Bold 20px/24px Verdana;">carrier at avalon</div>
            <div class="card-body">
                <p>Our employees are our precious assets. At Avalon, our associates are tasked with a multitude of everyday responsibilities related to site identification, land acquisition, land development, land leasing or construction.  They have to stay updated about the real estate regulations and trends and have to constantly respond to the changing needs of the clients. We, at Avalon, believe in the philosophy of constant learning in pursuit of growth and progress. We conduct regular training sessions to upgrade the job skills and enhance the knowledge of our employees in order for them to evolve with changing times and strive to be better with each passing day. Our educational programmes aim to specialise our employees in their craft and turn them into experienced professionals who consequently lead to the growth of the company. We want our associates to learn, work and evolve and also help them to maintain a healthy work-life balance.
                </p>
            </div>
            
          </div>
    </div>
    <div class=" card" style="background: #F8F8F8;">
       
        <div class=" card-header ">
             <div class="row" id="head1">
            <h4 class="col-md-4 col-sm-12" style="color:#F19F22;font-family: Bold  Didot LT Std;">Front Office Administrator</h4>

            <h6 class="col-md-3 col-sm-12 pt-1 " style="color: #2D2D2D;"><img src = "assets/img/Location.svg"  class="pr-3"> Pune</h6>

            <h6 class="col-md-3 col-sm-12 pt-1 " style="color: #2D2D2D;"> <img src = "assets/img/Date.svg"  class="pr-3"> Posted on, 28 June 2020</h6>
        </div>
  </div>
        <div class="card-body">
            <h6 style="font-family: Bold 20px/24px Verdana;">Role Expectation</h6>
            <ul style="font-size: 14px;">
                <li> Greet visitors, guests and invitees at reception and directing them to appropriate person </li>
                <li> Handle incoming / outbound calls at reception, EPABX and connecting calls to appropriate person </li>
                <li> Coordination for conference/meeting room bookings and assisting for arrangements and services</li>
                <li> Coordination and supervision of house-keeping staff and security staff to ensure office cleanliness, upkeep, security and record management </li>
                <li> To handle administrative work related to inward / out ward courier, supplies and inventories, record management, document filling, etc</li>
            </ul>
            <h6 style="font-family: Bold 20px/24px Verdana;">Eligible Candidature</h6>
            <ul style="font-size: 14px;">
                <li>Any graduate/Post-graduate with relevant experience of handing front desk of a Corporate Office/Luxury hotel/Franchise </li>
                <li>Individual with pleasing personality and good communication skills. Female candidate preferred  </li>
                <li>Candidate from hospitality and aviation industry will be preferred</li>
            </ul>
          
        </div>
    </div>

    <div class="card mt-5" style="background: #F8F8F8;">

        <div class="card-header">
             <div class="row" id="head1">
            <h4 class="col-md-4 col-sm-12 " style="color:#F19F22;font-family: Bold  Didot LT Std;">Sales Manager</h4>

            <h6 class="col-md-3 col-sm-12 pt-1" style="color: #2D2D2D;"><img src = "assets/img/Location.svg"  class="pr-3"> Pune</h6>

            <h6 class="col-md-3 col-sm-12 pt-1" style="color: #2D2D2D;"> <img src = "assets/img/Date.svg"  class="pr-3"> Posted on, 28 June 2020</h6>
        </div>
    </div>

        <div class="card-body">
            <h6 style="font-family: Bold 20px/24px Verdana;">Role Expectation</h6>
            <ul style="font-size: 14px;">
                <li> Develop an engagement activity plan, target plan and positioning strategy in conjunction with the sales plan for the product category in alignment to sales objective </li>
                <li> Evaluate effectiveness of existing sourcing channels and scout new sourcing channels. Generate new leads and review the existing lead relationships, through various joint activities with assigned and identified channels </li>
                <li> Undertaking the customer site visits to explain the product/project information and ensure delightful customer experience </li>
                <li> Working in conjunction with the sales department and handholding the customer across the sales lifecycle  </li>
                <li> • To collaborate with the marketing team in order to plan and deliver any promotional campaigns for the project/product assigned. And work in a team environment for Project Launch and prelaunch stage in support with the sales team.  </li>
            </ul>
            <h6 style="font-family: Bold 20px/24px Verdana;">Eligible Candidature</h6>
            <ul style="font-size: 14px;">
                <li>Graduate / Post graduate with relevant experience in Ultra-Luxury Real Estate.</li>
                 <li> Individual with experience and exposure of high-end sales in Branded Luxury Residences is a must </li> 
                <li>Exposure and experience of sales in luxury brands and products in automobile/wealth management/banking/hospitality/Jewellery industry will be preferred.</li> 
                <li> Post-graduation / PGD with Marketing/Finance/Sales from reputed B-Schools will be preferred. </li> 
                <li>Excellent communication and interpersonal skills • Well organized and target oriented individual </li> 
                 <li> Good team working skills and ability to work with minimal supervision</li>
               
            </ul>
        </div>
    </div>
   
</div>

</section>


<app-footer></app-footer>

<app-navbar></app-navbar>
<div class="container-fluid mt-5 pt-4 m-0 p-0 bg">
  <div style="background: rgba(0,0,0,0.2);height: 100%;">
    <p class="text-white pt-3 pb-3 pl-md-5 pl-2" style="background: rgba(0,0,0,0.5); opacity: 0.7;"><a class="text-white" routerLink="/"> Home </a> / About Us /
      Leadership</p>
    <h2 class="text-white"
      style="text-align: center;vertical-align: middle;line-height: 30vh; font: Bold 35px/186px Didot LT Std;">
      Leadership</h2>
  </div>
</div>

<section id="leader" class="">
  <div class="container pt-5" id="maincontent">

   <div class="row">
     <div class="col-md-12" *ngIf="selectedMember==1">
      <div class="card rounded-0 border-0" style="background: #F8F8F8;">
        <div class="row ">
          <div class="col-md-4">
            <img class="card-img-top" src="assets/img/IMG-20210922-WA0004.jpg" width="100%" height="auto" alt="Card image">
            </div>
            <div class="col-md-8 pl-0 pr-3">
              <div class="card-block pr-2 pl-4">
                <h5 class="card-titl pt-2">Anil Indulkar</h5>
                <small class="card-text pb-5 font-weight-bold">Chairman</small>
               <p class="mb-0 text-justify">The legacy of Hiroji Indulkar was carried on by his descendant Anil Indulkar. Pioneering architect Hiroji Indulkar was the great grandfather of Anil Indulkar. He studied in St. Josephs, Kohlapur and moved abroad to study at the University of California, Los Angeles (UCLA) in 1979. Back then, his entire family had a tradition of studying abroad. Anil wanted to carry on the tradition of the family and followed their footsteps. He established the Avalon Pharmacy in 1987 which offers convenient and fast patient care and medication to make the lives of the ailing masses easier. The business received an overwhelming response in US real estate that enabled Indulkar to develop a better understanding and experience. The pharmacy has completed a journey of thirty five years and continues to run successfully even today. In 1994, Anil Indulkar wanted to come back to his roots in Kohlapur where he spent the golden years of his childhood. The experience he had gathered abroad enabled him to cater to the Indian market in years to come. Pune was perhaps the closest link to his long lost roots in Kohlapur. His spectacular journey started with real estate, land trading and acquisitions in Pune in1994. The years spent abroad had helped him to realize the immense potential of the entertainment industry.
              </p>
              
              </div>
             
          </div>
          <div class="row">
            <div class="col-md-12">
              <p class="px-4 pt-0 text-justify">Splash Mountain Water Park situated in Lohegaon near Pune was his dream project which received a lot of popularity. It was heralded as the first and biggest water park in Pune back in those times. Spread over 25 acres of land, the water park offers an idyllic day out for both children and adults with a plethora of fun filled activities and adventures. The water slides and wavy pool still remain the favourites today.  Most importantly, the rides are designed separately for children and adults that make the water park a perfect destination for a family outing. In 2006, the Splash Mountain Water Park was renamed as Diamond Water Park. </p>    
            </div>
          </div>
         
        </div>
      </div>
     </div>
   </div>

    <!--<div class="card rounded-0 border-0 d-flex" *ngIf="selectedMember==1">
      <div class="card-body py-5">
        <div class="row">
        
           <div class="col-md-4 px-0 clearfix" style="background: #F8F8F8;"> 
              <img class="card-img-top" src="assets/img/arjun.png" width="100%" height="auto" alt="Card image">
             </div> 
           <div class="col-md-8 px-3" style="background: #F8F8F8;"> 
              
                <h5 class="card-title pt-0">Anil Indulkar</h5>
                <small class="card-text pb-5">The legacy continues</small>
               <p>The legacy of Hiroji Indulkar was carried on by his descendant Anil Indulkar. Pioneering architect Hiroji Indulkar was the great grandfather of Anil Indulkar. He studied in St. Josephs, Kohlapur and moved abroad to study at the University of California, Los Angeles (UCLA) in 1979. Back then, his entire family had a tradition of studying abroad. Anil wanted to carry on the tradition of the family and followed their footsteps. He established the Avalon Pharmacy in 1987 which offers convenient and fast patient care and medication to make the lives of the ailing masses easier. The business received an overwhelming response in US real estate that enabled Indulkar to develop a better understanding and experience. The pharmacy has completed a journey of thirty five years and continues to run successfully even today. In 1994, Anil Indulkar wanted to come back to his roots in Kohlapur where he spent the golden years of his childhood. The experience he had gathered abroad enabled him to cater to the Indian market in years to come. Pune was perhaps the closest link to his long lost roots in Kohlapur. His spectacular journey started with real estate, land trading and acquisitions in Pune in1994. The years spent abroad had helped him to realize the immense potential of the entertainment industry.
              </p>
              
              </div>
            </div> 
          <p>Splash Mountain Water Park situated in Lohegaon near Pune was his dream project which received a lot of popularity. It was heralded as the first and biggest water park in Pune back in those times. Spread over 25 acres of land, the water park offers an idyllic day out for both children and adults with a plethora of fun filled activities and adventures. The water slides and wavy pool still remain the favourites today.  Most importantly, the rides are designed separately for children and adults that make the water park a perfect destination for a family outing. In 2006, the Splash Mountain Water Park was renamed as Diamond Water Park. </p>
        </div>
    </div>-->
    <div class="row" *ngIf="selectedMember==2" style="background: #F8F8F8;">  
            <div class="col-md-4 pl-0 img-square-wrapper">
              <img class="card-img-top" src="assets/img/IMG-20210922-WA0005.jpg" alt="Card image">
            </div>
            <div class="col-md-8">
              <h5 class="card-title pt-2">Gouri Indulkar</h5>
              <small class="card-text  pb-2 font-weight-bold">The inspiring tale of the trailblazing woman</small>
              <p class="text-justify  mb-0">
                The increasing emergence of women entrepreneurs in every sector has brought about a huge transformation in the nature, characteristics and dynamics of business which has often consequently leads to the rapid growth and expansion of the enterprises. Over the years, the world has witnessed the greatest emancipation of women. They do not stay within the inner sanctums of the households anymore. They have finally come out of the cocoons, marked themselves free from regressive and redundant social norms, broken into the male bastion, touched lives, have emerged victorious in their respective endeavours and have ruled the world. Gauri Indulkar is one such strong woman whose spectacular journey might inspire millions who dare to dream and offer them wings to fly high. Gauri completed B.Sc in Chemistry from Fergison Pune. Her vast knowledge in Chemistry enabled her to excel and flourish in Pharmacy Business and acquire a real estate license in California , USA. She was highly successful in real estate opportunities in United States and the business gained a huge momentum in her supervision. Today, Gauri Indulkar is a strong figure of woman entrepreneurship in India and United States whose success story is truly spectacular.
              </p>
            </div>
    </div>
<!-- argin indulkar -->

<div class="row">
  <div class="col-md-12" *ngIf="selectedMember==3">
   <div class="card rounded-0 border-0" style="background: #F8F8F8;">
     <div class="row ">
       <div class="col-md-4">
         <img class="card-img-top" src="assets/img/arjun.png" width="100%" height="auto" alt="Card image">
         </div>
         <div class="col-md-8 pl-0 pr-3">
           <div class="card-block pr-3">
            <h5 class="card-title pt-2 font-weight-bold">Arjun Indulkar</h5>
            <small class="card-text pb-2 font-weight-bold">Managing Director</small> 
            <p class="mb-0 text-justify">
              Every generation of the iconic Indulkar family has produced gems who have turned out to be visionaries, in the true sense of the word. The legacy of Indulkars was then passed down to the capable hands of Arjun Indulkar. He was the second generation of the Indulkar family to enter the business. He completed M.Sc in Project Management from University of Reading, London ,United Kingdom and finally joined the family business in 2014. Arjun Indulkar is proficient in technology and is known for his eye for designs. Also known as a visionary, Arjun Indulkar has a unique ability to understand the pulse and the interests of the customers. He took up the mammoth task of taking care of the water park built by Anil Indulkar and also took up the responsibility of establishing Avalon Group Private Limited in real estate in Indian market. Since 2014, the company has completed two noteworthy projects. The Diamond Water Park has been revamped, remodeled and upgraded to meet the safety and hygiene guidelines of global standards. Today, Arjun Indulkar is also the president of the Diamond Adventure Park in Pune which facilitates adventure sports for adults like rock climbing, rocket banjee and zipline. 
           </p>
           </div>
       </div>
       <div class="row">
         <div class="col-md-12">
           <p class="px-4 pt-0 text-justify">The Diamond Adventure Park is well equipped with European safety features which ensure that the people enjoy the adventure sports to the fullest without stressing about the safety. This park offers a fresh retreat and premium entertainment to tired souls. It boasts of more than seven swimming pools, 32 international rides, kiddies pool, wet bubble, cyclone, play station Tortuga falls and much more fun filled activities. It also has a pure vegetarian restaurant that serves sumptuous delicacies and refreshing beverages.</p>
         </div>
       </div>
      
     </div>
   </div>
  </div>
</div>

<!-- arjun indulkar -->
<!-- 
      <div class="row d-flex border-0">
      <div class="col-md-12" *ngIf="selectedMember==3">
        <div class="card border-0" style="background: #F8F8F8;">
          <div class="card-horizontal ">
            <div class=" col-md-4 img-square-wrapper px-0">
              <img class="card-img-top" src="assets/img/arjun.png" width="100%" alt="Card image">
            </div>
            <div class="card-body col-md-8 px-3">
              <h5 class="card-title pt-2">Arjun Indulkar</h5>
              <small class="card-text pb-2">Mr.Arjun Indulkar,the MD of Indulkar Group o Companies.
              </small> 
              <p class="card-text text-justify">
                Every generation of the iconic Indulkar family has produced gems who have turned out to be visionaries, in the true sense of the word. The legacy of Indulkars was then passed down to the capable hands of Arjun Indulkar. He was the second generation of the Indulkar family to enter the business. He completed M.Sc in Project Management from University of Reading, London ,United Kingdom and finally joined the family business in 2014. Arjun Indulkar is proficient in technology and is known for his eye for designs. Also known as a visionary, Arjun Indulkar has a unique ability to understand the pulse and the interests of the customers. He took up the mammoth task of taking care of the water park built by Anil Indulkar and also took up the responsibility of establishing Avalon Group Private Limited in real estate in Indian market. Since 2014, the company has completed two noteworthy projects. The Diamond Water Park has been revamped, remodeled and upgraded to meet the safety and hygiene guidelines of global standards. Today, Arjun Indulkar is also the president of the Diamond Adventure Park in Pune which facilitates adventure sports for adults like rock climbing, rocket banjee and zipline. The Diamond Adventure Park is well equipped with European safety features which ensure that the people enjoy the adventure sports to the fullest without stressing about the safety. This park offers a fresh retreat and premium entertainment to tired souls. It boasts of more than seven swimming pools, 32 international rides, kiddies pool, wet bubble, cyclone, play station Tortuga falls and much more fun filled activities. It also has a pure vegetarian restaurant that serves sumptuous delicacies and refreshing beverages.

              </p>
              
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row border-0">
      <div class="col-md-12" *ngIf="selectedMember==4">
        <div class="card border-0" style="background: #F8F8F8;">
          <div class="row">
            <div class=" col-md-4 img-square-wrapper px-0">
              <img class="card-img-top" src="assets/img/raveena.jpg" width="100%" alt="Card image">
            </div>
            <div class=" col-md-8 card-body px-3">
              <h5 class="card-title pt-2">Ravina Indulkar</h5>
              <small class="card-text pb-2 font-weight-bold">The interior designer extraordinaire</small>
              <p class="card-text text-justify">
                Raveena Indulkar is another important member of the Indulkar family who is also holding on to the legacy. She is an architect by profession and her passion lies in interior designing.  She studied in Cummins College of Engineering for Women, Pune and completed her Masters and specialization in interiors from New York School of Interior Design. The watershed moment of her career was working with the one of the most prominent decorator and architect of America, Jeffrey Bilhuber who has almost thirty years of extensive experience in this field. Raveena chose New York in pursuit of exploring her craft further and experimenting with working styles. Her vision is to take her company to new heights, match up to the standards of New York and make a mark in the global arena in the areas of interior designing and architecture.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row border-0">
      <div class="col-md-12" *ngIf="selectedMember==5">
        <div class="card border-0" style="background: #F8F8F8;">
          <div class="row">
            <div class=" col-md-4 img-square-wrapper px-0">
              <img class="card-img-top" src="assets/img/Patwa Sir.jpg" alt="Card image" style="height: 350px;">
            </div>
            <div class=" col-md-8 card-body px-3">
              <h5 class="card-title">Hitesh Patwa</h5>
              <small class="card-text pb-2 font-weight-bold">Efficient member of Avalon Developers </small>
              <p class="card-text">
                Hitesh Patwa is another important member of Avalon Developers who has completed his graduation from abroad. He works in a managerial post and is known for his efficiency and sincerity. Hitesh has worked in various significant projects like Nyati and is always ready to walk the extra mile to make the company reach greater heights. 
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
<!-- bottom row card section -->
<div class="row border-0 pt-5">

  <div class=" col-lg-3 mb-3" (click)="getCurrentElementId(2)"  *ngIf="selectedMember!=2">
    <div class="card border-0" style="background: #F8F8F8;" (change)="getImage($event)"
      [ngClass]="{'active':'selected.currentimg'}">
      <img class="card-img-top" src="assets/img/IMG-20210922-WA0005.jpg" width="100%" style="height: 238px;" alt="Card image">
      <div class="card-body p-2">
        <h5 class="text-left card-title mb-0">Gouri Indulkar</h5>
        <small class="card-text">The inspiring tale of the trailblazing woman 
        </small>
      </div>
    </div>
  </div>

  <div class=" col-lg-3 mb-3" (click)="getCurrentElementId(3)"  *ngIf="selectedMember!=3">
    <div class="card border-0 pb-4" style="background: #F8F8F8;" [ngClass]="{'active':'selected.currentimg'}">
      <img class="card-img-top" src="assets/img/arjun.png" width="100%" alt="Card image">
      <div class="card-body p-2">
        <h5 class=" text-left card-title mb-0">Arjun Indulkar</h5>
        <small class="card-text pb-2">Managing Director</small>
      </div>
    </div>
  </div>

  <div class=" col-lg-3 mb-3" (click)="getCurrentElementId(4)"  *ngIf="selectedMember!=4">
    <div class="card border-0 pb-4" style="background: #F8F8F8;" [ngClass]="{'active':'selected.currentimg'}">
      <img class="card-img-top" src="assets/img/raveena.jpg" width="100%" alt="Card image" style="height: 240px;">
      <div class="card-body p-2">
        <h5 class=" text-left card-title mb-0 ">Ravina Indulkar</h5>
        <small class="card-text">The interior designer extraordinaire</small>
      </div>
    </div>
  </div>

  <div class=" col-lg-3 mb-3" (click)="getCurrentElementId(5)"  *ngIf="selectedMember!=5">
    <div class="card border-0 " style="background: #F8F8F8;" [ngClass]="{'active':'selected.currentimg'}">
      <img class="card-img-top" src="assets/img/Patwa Sir.jpg" width="100%" alt="Card image" style="height: 240px;">
      <div class="card-body p-2">
        <h5 class=" text-left card-title mb-0 ">Hitesh Patwa</h5>
        <small class="card-text ">Efficient member of Avalon Developers</small>
      </div>
    </div>
  </div>

  <div class=" col-lg-3 mb-3" (click)="getCurrentElementId(1)"  *ngIf="selectedMember!=1">
    <div class="card pb-4 border-0" style="background: #F8F8F8;" [ngClass]="{'active':'selected.currentimg'}">
      <img class="card-img-top" src="assets/img/IMG-20210922-WA0004.jpg" width="100%" style="height: 238px;" alt="Card image">
      <div class="card-body p-2">
        <h5 class=" text-left card-title mb-0">Anil  Indulkar</h5>
        <small class="card-text">Chairman</small>
      </div>
    </div>
  </div>

</div>
<!-- bottom row  card section--end -->

    </div><!-- top container -->
</section>
<!-- section first End -->
<app-footer></app-footer>



















<!-- section second -->
<!------
    <div class="row border-0">

      <div class=" col-md-3 " (click)="getCurrentElementId(2)">
        <div class="card border-0" style="background: #F8F8F8;" (change)="getImage($event)"
          [ngClass]="{'active':'selected.currentimg'}">
          <img class="card-img-top" src="assets/img/arjun.png" width="100%" alt="Card image">
          <div class="card-body">
            <h5 class="card-title">Gouri Indulkar :</h5>
            <small class="card-text">The inspiring tale of the trailblazing woman</small>
          </div>
        </div>
      </div>

      <div class=" col-md-3" (click)="getCurrentElementId(3)">
        <div class="card border-0" style="background: #F8F8F8;" [ngClass]="{'active':'selected.currentimg'}">
          <img class="card-img-top" src="assets/img/arjun.png" width="100%" alt="Card image">
          <div class="card-body">
            <h5 class="card-title">Arjun Indulkar</h5>
            <small style="color: #707070;">Mr. Arjun Indulkar, the MD of Indulkar Group of Companies.</small>
          </div>
        </div>
      </div>

      <div class=" col-md-3" (click)="getCurrentElementId(4)">
        <div class="card border-0" style="background: #F8F8F8;" [ngClass]="{'active':'selected.currentimg'}">
          <img class="card-img-top" src="assets/img/arjun.png" width="100%" alt="Card image">
          <div class="card-body">
            <h5 class="card-title">Ravina Indulkar</h5>
            <small class="card-text">The interior designer extraordinaire:</small>
          </div>
        </div>
      </div>

      <div class=" col-md-3" (click)="getCurrentElementId(5)">
        <div class="card border-0" style="background: #F8F8F8;" [ngClass]="{'active':'selected.currentimg'}">
          <img class="card-img-top" src="assets/img/arjun.png" width="100%" alt="Card image">
          <div class="card-body">
            <h5 class="card-title">Hitesh Patwa :</h5>
            <small class="card-text">Efficient member of Avalon Developers</small>
          </div>
        </div>
      </div>

    </div> -->


<div class="container-fluid mt-3 font" id="footer1" style="padding: 0 !important;">
    <div class="row footer-links mb-3" style="padding: 0 100px 0 110px;" id="footerlinks">
        <div class="col-lg-2 col-xs-12 col-sm-6 " id="portfolio1">
            <!-- pl-5 pl-16 -->
            <a class="text-uppercase cursor btn-color" >PORTFOLIO</a>
            <a href="https://avalondevelopers.com/html/home_property.html?id=7" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Evara - 1</a><br>
            <a href="https://avalondevelopers.com/html/home_property.html?id=6" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Evara - 2</a>
            <!-- <a href="#" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Hosptality</a><br>
            <a href="#" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Retail & F&B</a><br>
            <a href="#" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Ongoing Project</a>-->
        </div>
        <!-- <div class="col-lg-2 col-xs-12 col-sm-6 ">
            <a href="#" class="text-uppercase cursor btn-color">Services</a>
            <a href="#" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Project Management</a><br>
            <a href="#" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Facility Management</a><br>
            <a href="#" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Fit-Out Management</a><br>
            <a href="#" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Architecture</a><br>
        </div> -->
        <div class="col-lg-2 col-xs-12 col-sm-6">
            <a  class="text-uppercase cursor btn-color">CorporateProfile</a>
            <a class="cursor btns" routerLink="/foreward"><i class="fa fa-arrow-right" id="ic"></i> Foreword</a><br>
            <a routerLink="/roadmap" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Growth Chroniches</a><br>
            <a routerLink="/profile" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Company Profile</a><br>
            <a routerLink="/philosophy" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Group Philosophy</a>
            <!-- <a href="#" class="text-uppercase cursor btn-color">BLOG</a> -->
        </div>
        <div class="col-lg-2 col-xs-12 col-sm-6">
            <a  class="text-uppercase cursor btn-color">Media</a>
            <a routerLink="/media" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Our Gallery</a><br>
            <!-- <a href="#" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Media Kit</a><br> -->
            <a routerLink="/media" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Our Story</a><br>
            <!-- <a href="#" class="text-uppercase cursor btn-color">DownLoads</a> -->
        </div>
        <div class="col-lg-2 col-xs-12 col-sm-6">
            <a class="text-uppercase cursor btn-color">Carrer</a>
            <a routerLink="/life" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Life At Avalon</a><br>
            <a href = "https://indulkars.easyhrworld.com/outside/careers" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Job Opportunities</a><br>
            <!-- <a href="#" class="text-uppercase cursor btn-color">Leadership</a> -->
        </div>
        <div class="col-lg-2 col-xs-12 col-sm-6">
            <a class="text-uppercase cursor btn-color">Sustainability</a>
            <a routerLink="/healthandsafety" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Quality, Environment, Health and
                Safety</a><br>
            <a routerLink="/healthsafety" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Corporate Social
                Responsbility</a><br><br>
            <!-- <a href="#" class="text-uppercase cursor btn-color">Clients</a> -->
        </div>
        <div class="col-lg-2 col-xs-12 col-sm-6">
            <a class="text-uppercase cursor btn-color">Quick Links</a>
            <a routerLink="/blogs" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Blog</a><br>
            <a href="#" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Downloads</a><br>
            <a routerLink="/leadershp" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Leadership</a><br>
            <a href="#" class="cursor btns"><i class="fa fa-arrow-right" id="ic"></i> Clients</a>

        </div>
    </div>


    <div id="foo" class="border-top">
        <div class="container-fluid mt-0 pt-0">
            <div class="row  ml-md-5 mr-md-5">
                <!-- border-top -->
                <div class="col-lg-4 col-md-6 col-12" id="ff">
                    <!-- border-right -->
                    <p class="text-uppercase footer-header pt-3">Contact</p>
                    <div class="row">
                        <div class="col-12">
                            <p class="text-uppercase footer-header">Corporate Office</p>
                            <p class="office-address">S.No 41 B/2 Mouje Lohegaon, <br> Lohegaon Pune Water Park Road, <br> Maharashtra 411047</p>
                            <p class="text-uppercase footer-header mb-0">Mail</p>
                            <a href="#" class="office-address">contact@avalondevelopers.com</a>
                            <p class="text-uppercase footer-header mb-0 mt-3">Customer Service</p>
                            
                            <a href="#" class="office-address">7721008844</a>
                           
                        </div>
                        <!-- <div class="col-6">
                            <p class="text-uppercase footer-header">Mumbai Office</p>
                            <p class="office-address">Lorem Ipsum, Lorem Ipsum, Mumbai - 411 045. India</p><br><br><br><br>
                             <a href="#" class="office-address">T: +91 20 0000-0000</a>
                        </div> -->
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-12 location-map text-center " id="ff">
                    <!-- border-right -->
                    <h5 class="footer-header pt-3 text-left">LOCATION ON MAP</h5>
                    <!--<div class="location-content text-left">
                        <span>Select city : </span>
                        <select class="location-dropdown-list" onchange="locationonMap(this.value)">
                            <option>Select</option>
                            <option value="pune" selected="">Pune</option>
                        </select>
                    </div><br>-->
                    <img src="assets/img/map1.png" alt="world map" class="img-fluid" style=" height: 240px;">
                </div>
                <div class="col-lg-4 col-md-6 col-12 pt-3" id="lastf">
                    <!--                 <h6>FOLLOW US ON</h6>
                <ul class="social-icon"  id="menu">
                    <li><a href=""><i style="color: #828080" class="fab fa-facebook"></i></a></li>
                   <li><a href=""><i style="color: #828080" class="fab fa-instagram"></i></a></li>
                   <li><a href=""><i style="color: #828080" class="fab fa-linkedin-in"></i></a></li>
                   <li><a href=""><i style="color: #828080" class="fab fa-youtube"></i></a></li>
                </ul> -->
                    <!-- <p style="color: #000;font-size: 12px;padding-top: 30px;"><img
                            src="assets/img/Nyati-Victoria-logo.png" class="pr-3" > VICTORIA MAJESTIC
                        BUNGALOWS</p>
                    <p style="color: #000;font-size: 12px;"><img src="assets/img/Erica.png" class="pr-3" >
                        ERICA - PHASE 1</p>
                    <p style="color: #000;font-size: 12px;"><img src = "assets/img/customer_icon.png"  class="pr-3" >  
                        CUSTOMER LOGIN </p>
                    <p style="color: #000;font-size: 12px;"><img src="assets/img/nri_icon.png" class="pr-3"
                            >
                         NRI </p>
                    <p style="color: #000;font-size: 12px;"><img src="assets/img/real_estate_consultant_icon.png"
                            class="pr-3" >REAL ESTATE CONSULTANT </p> -->

                            <p style="color: #000;font-size: 12px;padding-top: 30px;"><a href="https://play.google.com/store/apps/details?id=com.HaloKitchen"> <img
                                src="assets/img/Nyati-Victoria-logo.png" class="pr-3" style="margin-right: 10px;"> HALO KITCHEN APP</a> </p>
                        <p style="color: #000;font-size: 12px;"><img src="assets/img/Erica.png" class="pr-3" >
                            <a href="https://avalondevelopers.com/html/home_property.html?id=7">EVARA 1</a></p>
                        <p style="color: #000;font-size: 12px;"><img src = "assets/img/customer_icon.png"  class="pr-3" >  
                            <a href="http://www.diamondparks.com/allTickets">BUY DIAMOND WATER PARK TICKETS</a> </p>
                        <p style="color: #000;font-size: 12px;"><img src="assets/img/nri_icon.png" class="pr-3"
                                >
                            <a href="https://avalondevelopers.com/html/home_property.html?id=6">EVARA 2</a> </p>
                        <p style="color: #000;font-size: 12px;"><img src="assets/img/real_estate_consultant_icon.png"
                                class="pr-3" style="margin-right: 10px;"><a href="https://avalonpharmacy.flashrx.com/default.asp"> REFILL THE MEDICINE</a> </p>        

                </div>
            </div>
        </div>
    </div>

    <!-- <div class="container-fluid mt-5 ml-0 mr-0 pl-0 pr-0">
    <div class="card-group last">
        <div class="card img-fluid image showextra" style="height : 60.2vh">
            <div class="card-body p-0">
                <img class="card-img-top" src="assets/img/diamond.jpg" alt="Card image" style="width:100%;height:60vh">
            <div class="card-img-overlay text-center text-white p-0 pt-3" style="background: rgba(0,0,0,0.5);">
                <div class="card extra">
                       <div class="card-header">
                        <p class="text-center pt-4">DIAMOND PARK</p>
                       </div>
                       <div class="card-body text-left">
                        <div class="text-white font">
                            
                                <p class="mb-2">WATER PARK</p>
                                <p class="mb-2">ADVENTURE PARK</p>
                                <p class="mb-2">CAMP</p>
                                <p class="mb-2">EVENT</p>
                                <p class="mb-2">BOOK NOW</p>
                                <p class="mb-2">CORPORATE BOOKING</p>
                            
                          </div>
                       </div> 
                </div>

                <h4 class="card-title title1 text-uppercase"></h4><br><br><br><br><br><br><br><br>
              <a href="#" class="btn btn-info mt-4">DIAMOND PARK</a>
            </div>
            </div>    
        </div>
        <div class="card img-fluid image showextra" style="height : 60.2vh">
            <div class="card-body p-0">
                <img class="card-img-top" src="assets/img/slider/01 Victoria.jpg" alt="Card image" style="width:100%;height:60vh">
            <div class="card-img-overlay text-center text-white" style="background: rgba(0,0,0,0.5);">
                <div class="card extra ">
                       <div class="card-header">
                        <p class="text-center pt-4">AVALON Realty</p>
                       </div>
                       <div class="card-body text-left">
                        <div class=" font text-white">
                            
                                <p class="mb-2">WATER PARK</p>
                                <p class="mb-2">ADVENTURE PARK</p>
                                <p class="mb-2">CAMP</p>
                                <p class="mb-2">EVENT</p>
                                <p class="mb-2">BOOK NOW</p>
                                <p class="mb-2">CORPORATE BOOKING</p>
                            
                          </div>
                       </div> 
                </div>

                <h4 class="card-title title1 text-uppercase"></h4><br><br><br><br><br><br><br>
              <a href="#" class="btn btn-info mt-4">AVALON Realty</a>
            </div>
            </div>    
        </div>
        <div class="card img-fluid image showextra" style="height : 60.2vh">
            <div class="card-body p-0">
                <img class="card-img-top" src="assets/img/AdobeStock_144288967.jpg" alt="Card image" style="width:100%;height:60vh">
            <div class="card-img-overlay text-center text-white" style="background: rgba(0,0,0,0.5);">
                <div class="card extra">
                       <div class="card-header">
                        <p class="text-center pt-4">AVALON DRUGGIST</p>
                       </div>
                       <div class="card-body text-left">
                        <div class="text-white  font" >
                            
                                <p class="mb-2">WATER PARK</p>
                                <p class="mb-2">ADVENTURE PARK</p>
                                <p class="mb-2">CAMP</p>
                                <p class="mb-2">EVENT</p>
                                <p class="mb-2">BOOK NOW</p>
                                <p class="mb-2">CORPORATE BOOKING</p>
                            
                          </div>
                       </div> 
                </div>

                <h4 class="card-title title1 text-uppercase"></h4><br><br><br><br><br><br><br>
              <a href="#" class="btn btn-info mt-4">AVALON DRUGGIST</a>
            </div>
            </div>    
        </div>


       
      </div>
</div> -->

    <!-- <div class="footer bg-dark text-white navbar ">
        <div class="copy-right pt-3">
            <div class="container-fluid">
                <div class="row pt-2" justify-content->
                    <div class="col-md-2 col-sm-3 col-xs-4">

                        <a href="#" class="text-white">
                            <p>Privacy Policy</p>
                        </a>
                    </div>

                    <div class="col-md-2 col-sm-3 col-xs-5">
                        <a href="#" class="text-white">
                            <p>Terms &amp; Conditions</p>
                        </a>
                    </div>


                    <div class="col-md-2 col-sm-3 col-xs-4">

                        <a href="#" class="text-white">
                            <p>Disclaimer</p>
                        </a>
                    </div>



                    <div class="col-md-3 col-sm-6">
                        <p class="pull-right">Copyright, all rights reserved</p>
                    </div>

                    <div class="col-md-3 col-sm-6">
                        <p class="pull-right">Developed BY <a href="https://puretechnology.in/" rel="nofollow"
                                target="_blank" style="text-decoration:none;color:#a9a9a9;">PureTech Codex Pvt. Ltd.</a>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
 -->


    <!-- <div class="container-fluid m-auto fixed-bottom sticky-top" style="background-color: #343A40">
            <div class="row">
                <div class="col">
                    <a href = "#"><img src = "assets/img/LinkedIn.svg" style="height:40px"></a>
                </div>
                <div class="col">
                    <a href = "#"><img src = "assets/img/Twitter.svg" style="height:40px"></a>
                </div>
                <div class="col">
                    <a href = "#"><img src = "assets/img/YouTube.svg" style="height:40px"></a>
                </div>
                <div class="col">
                    <a href = "#"><img src = "assets/img/Facebook.svg" style="height:40px"></a>
                </div>
                <div class="col">
                    <a href = "#"><img src = "assets/img/Instagram.svg" style="height:40px"></a>
                </div>
            </div>
        </div>
			 -->

   <!--  <div class="icon-bar">
        <a href="#" class="facebook"><img src="assets/img/LinkedIn.svg" style="height:40px"></a>
        <a href="#" class="twitter"><img src="assets/img/Twitter.svg" style="height:40px"></a>
        <a href="#" class="google"><img src="assets/img/YouTube.svg" style="height:40px"></a>
        <a href="#" class="linkedin"><img src="assets/img/Facebook.svg" style="height:40px"></a>
        <a href="#" class="youtube"><img src="assets/img/Instagram.svg" style="height:40px"></a>
    </div> -->


 <div class="copy-right" id="copy">
        <div class="container">
            <div class="row">
                <div class="col-md-2 col-sm-3 col-xs-4">
        
        <a routerLink="/privacy-policy">
          <p>Privacy Policy</p>
        </a>
        </div>

        <div class="col-md-2 col-sm-3 col-xs-5">
        <a routerLink="/terms-and-conditions">
          <p>Terms & Conditions</p>
        </a>
                </div>


        <div class="col-md-2 col-sm-3 col-xs-3">
        
        <a routerLink="/desclaimer">
          <p>Disclaimer</p>
        </a>
        </div>

        <div class="col-md-3 col-sm-6">
          <p class="pull-right">Copyright, all rights reserved</p>
        </div>

        <div class="col-md-3 col-sm-6">
          <p class="pull-right">SEEDED BY <a href="http://puretechnology.in/" rel="nofollow" target="_blank" style="text-decoration:none;color:#a9a9a9;">PURE TECH CODEX</a></p>
        </div>


            </div>
</div>


<!-- <div class="container">
    <div class="row"> -->
<div class="icon-bar ">
  <a href="#" class="facebook"><img src = "assets/img/LinkedIn.svg" ></a> 
  <a href="#" class="twitter"><img src = "assets/img/Twitter.svg" ></a> 
  <a href="#" class="google"><img src = "assets/img/YouTube.svg"></a> 
  <a href="#" class="linkedin"><img src = "assets/img/Facebook.svg"></a>
  <a href="#" class="youtube"><img src = "assets/img/Instagram.svg" ></a> 
<!-- </div>

     </div>   --> 
    </div>
</div>   
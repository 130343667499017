<app-navbar></app-navbar>

<div class="container-fluid mt-5 pt-4 m-0 p-0 bg" style="width: 100%;">
    <div style="background: rgba(0,0,0,0.5);height: 100%;">
        <p class="text-white pt-3 pb-3 pl-md-5 pl-2" style="background: rgba(0,0,0,0.5); opacity: 0.7;"><a class="text-white" routerLink="/"> Home </a> / About Us / Corporate Profile / Company Profile</p>
        <h2 class="text-white" style="text-align: center;vertical-align: middle;line-height: 30vh; font: Bold 35px/186px Didot LT Std;">Company Profile</h2>
    </div>
</div>
<section class="business-section" id="profile1">
    <div class="container business-container">
        <div class="row">
            <div class="col-md-12 foreward-text" style="text-align:center;">
               <!--  <p>Established in 2002, Panchshil Realty is one of India&#039;s finest luxury real estate developers. Renowned for its leadership and excellence in real estate development, Panchshil has successfully delivered over 23 million sq. ft. of prime real estate, with 20 million sq. ft. under development across multi-asset classes.</p><p>Panchshil&#039;s portfolio spans across high-end residential developments, IT parks, built-to-suit office spaces, special economic zones (SEZ), hotels, convention centres and luxury retail malls. The company’s developments comprise of key landmarks of tomorrow&#039;s Pune, such as India’s first internationally branded residences yoopune by Philippe Starck and John Hitchcox, and the first Trump branded residences – Trump Towers Pune, EON Free Zone in Kharadi - a 4.5 million sq. ft. IT &amp; IT-eS SEZ spread over 57 acres, the International Convention Centre - South Asia&#039;s largest trade and convention centre, World Trade Center Pune, and an award-winning built-to-suit office campus for Cummins India amongst others.</p><p>With global partners like YOO, Trump Organization, JW Marriott, The Ritz-Carlton, Hilton, Oakwood and World Trade Center Association amongst others, Panchshil has played a significant role in putting Pune on the world map and added to its growing global identity.</p><p>Recipient of multiple awards in luxury homes and office space development, Panchshil is synonymous with premium finish, intelligent floor layouts, innovative solutions, high-quality execution, and after-sales maintenance.</p>                <br/><br/><br/> -->
                <h2 class="didot-font"></h2>
                  <!-- start here to put new content for company profile-->
        <p class="text-justify">Avalon Developers have emerged as a name to reckon with in the real estate industry. Founded in 5th July 1999, Avalon presently has its operation area in Pune. Serving the best of the world class properties to the premium customers, we have been able to create a strong family of many happy customers with zero litigation and have grown from strength to strength with the passage of time. Known for fairness, loyalty, timeliness, sincerity, efficiency, quality of work, landmark projects, Avalon Developers, the real estate company has evolved as a trusted name in the real estate sector. </p>
        <p class="text-justify">The area of operation of Avalon Developers span across multiple aspects of real estate development including land identification, land acquisition, land leasing, land development, land agreement, construction, project planning, designing, property management and a lot more. We are known for comprehensive services to the corporate clients in the real estate business. When a client expresses the desire of buying a particular property, we take the entire responsibility on our shoulders thereby rendering multi-faceted services of real estate services ranging from land development to selling. Primarily serving premium corporate clients, we aim at the optimum satisfaction of our clients with our pioneering work in property management with our level of expertise and skill set in real estate activities. </p>
        <p class="text-justify">Having both commercial and residential projects under its wings, Avalon Developers, the real estate company is actively involved in all the property related transactions including land acquisition, land leasing and construction. </p>
        <p class="text-justify">Avalon developers aim to <strong>focus</strong> of prioritizing the needs of the customers. The process of working is simple and completely devoid of hassles. Depending on whether our client is looking for a residential or commercial project, we do the process of site selection followed by land acquisition or sourcing of property. Keeping the best interest of our clients in mind, we identify the development and project management services. The process of land development includes the evaluation, planning, engineering, construction, services associated with project management services and many more. We work with a team of experienced professionals who are adept in offering the suitable development for the site. Our highly efficient team guides you through each step of the process of land development from site feasibility to construction thereby converting your dream into Realty. The next step that comes is the selling of the land. Committed to offer an exceptional experience to the customers, Avalon  Developers, the real estate company has established itself as one of the highly coveted key players in the real estate sector of Pune.</p> 
         <!---end here to put new content for company profile-->
    </div>
        </div>
    </div>
</section>

<app-footer></app-footer>


<div class="container-fluid mt-5 pt-4 m-0 p-0 bg">
    <div class="background pt-5">
        <div class="col-9 pt-1">
            <h2 class="text-uppercase pl-5 ml-5" >Avalon realty</h2>
            <p class="text-white pl-5 ml-5">LET THE LUXURY DO THE MAGIC. EXPLICITLY MAJESTIC, NYATI VICTORIA IS YOUR GATEWAY TO A REFINED LIVING IN ONE OF THE MOST DESIRED NEIGHBOURHOODS OF PUNE, NYATI COUNTY. INSPIRED FROM EUROPEAN ARCHITECTURE, THIS GRAND PROJECT OF WELL-DESIGNED MAJESTIC BUNGALOWS IS AN EXAMPLE OF CLASSIC ELEGANCE AND CONTEMPORARY LUXURY.</p>
            <button class="btn btn-info text-uppercase">visit us</button>
        </div>
    </div> 
</div>

<div class="container pt-5 h4font">
    <div class="col-12 text-center">
        <h3 style="color: black;">Our Projects</h3>
    </div>

    <div class="row pt-5">
        <!-- <div class="col-md-6">
            <img src = "assets/img/Evara-2 - Upcomming.jpg" style="width: 97%;height:300px">
        </div> -->
        
        <div class="col-md-6 small">
            <img src="assets/img/Evara-2 - Upcomming.jpg" style="width: 78%;height:300px" class="alignleft size-medium wp-image-7000" />
        </div>  
        <div class="col-md-6 " style="margin : auto;margin-left:-4%">
          
            <hr width="20%" style="margin-left: 0;border: 1px solid #F19F22;"> <h5 class="">01</h5>
            <div style="background: #F8F8F8;padding:10px;padding-left:25px">
                <h3>Evara-2</h3>
                <p class="our-p">NESTLED AMIDST THE QUIET, YET WELL-CONNECTED SUBURB OF NIBM PUNE, NYATI EVARA BLOOMS BEAUTIFULLY AS IT SPREADS FRAGRANCE ON EVERY LIFE THAT BLOSSOMS HERE. COMPRISING EXCLUSIVE 2 RHK APARTMENTS, THE PROJECT BEAUTIFULLY INTEGRATES ALL THE ASPECTS OF LUXURY LIVING AT AN AFFORDABLE PRICE. EVERY APARTMENT HAS BEEN DESIGNED TO BALANCE LUXURY AND ELEGANCE, VALUE AND COMFORT.</p>
            </div> 
        </div>
    </div>
    <div class="row pt-5">
        
        <div class="col-md-6 " style="margin : auto;margin-right:-4%">
          
            <h5  style="margin-left: 74%;">02</h5><hr width="20%" style="margin-right: 0;border: 1px solid #F19F22;margin-top:-24px"> 
            <div style="background: #F8F8F8;padding:10px;padding-left:25px;margin-top:30px">
                <h3>Erica - Second Phase</h3>
                <p class="our-p">NESTLED AMIDST THE QUIET, YET WELL-CONNECTED SUBURB OF NIBM PUNE, NYATI EVARA BLOOMS BEAUTIFULLY AS IT SPREADS FRAGRANCE ON EVERY LIFE THAT BLOSSOMS HERE. COMPRISING EXCLUSIVE 2 RHK APARTMENTS, THE PROJECT BEAUTIFULLY INTEGRATES ALL THE ASPECTS OF LUXURY LIVING AT AN AFFORDABLE PRICE. EVERY APARTMENT HAS BEEN DESIGNED TO BALANCE LUXURY AND ELEGANCE, VALUE AND COMFORT.</p>
            </div> 
        </div>
        <div class="col-md-6 small">
            <img src = "assets/img/Features Project/02 Erica - Second Phase.jpg" style = "width:79%;">
        </div>  
       
    <!--<div class="col-md-6">
            <img src = "assets/img/Features Project/02 Erica - Second Phase.jpg" style = "width:97%;">
        </div> -->
    </div>
    <div class="row pt-5">
        <div class="col-md-6 small">
            <img src = "assets/img/Esteban_banner - Ongoing.jpg"  style = "width:78%;height:300px">
        </div>
        <div class="col-md-6 " style="margin : auto;margin-left:-4%">
          
            <hr width="20%" style="margin-left: 0;border: 1px solid #F19F22;"> <h5 class="">03</h5>
            <div style="background: #F8F8F8;padding:10px;padding-left:25px">
                <h3>Esteban</h3>
                <p class="our-p">NESTLED AMIDST THE QUIET, YET WELL-CONNECTED SUBURB OF NIBM PUNE, NYATI EVARA BLOOMS BEAUTIFULLY AS IT SPREADS FRAGRANCE ON EVERY LIFE THAT BLOSSOMS HERE. COMPRISING EXCLUSIVE 2 RHK APARTMENTS, THE PROJECT BEAUTIFULLY INTEGRATES ALL THE ASPECTS OF LUXURY LIVING AT AN AFFORDABLE PRICE. EVERY APARTMENT HAS BEEN DESIGNED TO BALANCE LUXURY AND ELEGANCE, VALUE AND COMFORT.</p>
            </div> 
        </div>
    </div>

    <div class="col-12 text-center mt-5">
        <button  class="btn btn-default text-uppercase text-dark border border-dark mb-4 pl-5 pr-5" style="border : 2px solid #000!important;font-weight: 700px;">Visit Us</button>
    </div>
</div>

<div class="container-fluid m-0 p-0 bg1">
    <div class="row" style="background: rgba(0,0,0,0.8);height:70vh;">
            <div class="col-md-6 text-right" style="margin:auto;" >
                <img src="assets/img/slider/01 Victoria.jpg" class=" pr-5" height = "400px">
            </div>
            <div class="col-md-6" style="margin:auto">
                <h2 class="text-white text-uppercase mb-0" style="font-family: Bold 50px/60px Verdana;">New project <br> Victoria</h2>

                <div class="row">
                     <div class="col-2">
                        <hr  style="margin-left: 0;border: 1px solid #fff;margin-top: 40px;"> 
                     </div>   
                     <div class="col">
                        <button  class="btn btn-info mt-4 text-uppercase text-white border border-white mb-4 pl-5 pr-5" >Book Now</button>
                     </div>
                </div>
            </div>
    </div>
</div>
<app-navbar></app-navbar>

<!-- <div style="height:40px; width: 100%;"></div> -->
<div class="container-fluid mt-5 pt-4 m-0 p-0 bg">
  <div style="background: rgba(0,0,0,0.5);height: 100%;">
    <p class="text-white pt-3 pb-3 pl-md-5 pl-2" style="background: rgba(0,0,0,0.5);"><a class="text-white" routerLink="/"> Home </a> / Contact</p>
    <h2 class="text-white"
      style="text-align: center;vertical-align: middle;line-height: 30vh; font: Bold 35px/186px Didot LT Std;">Contact
    </h2>
  </div>
</div>

<section id="contact1">
  <div class="container">
    <div class="col-12 text-center">
      <h4 style="font-family: 'didot';">Enquiry Form</h4>

      <form [formGroup]="contactForm" id="contactForm" class="mt-5" style="margin-bottom: 0px;" (submit)="onSubmit()">

        <div class="row">
          <div class="col-md-6 ">
            <div class="form-group">
              <input type="text" class="form-control" placeholder="First Name"  name="firstName"
                id="firstName" formControlName="firstName"  [ngClass]="{'is-invalid': submitted && f.firstName?.errors }">
<!-- 
              <div *ngIf="contactForm.controls['firstName'].touched && contactForm.controls['firstName'].invalid"
                class="text-danger text-left">
                <div
                  *ngIf="contactForm.controls['firstName'].errors && contactForm.controls['firstName'].errors.required"
                  class="text-left">First name is required.</div>
              </div> -->
              <div *ngIf="submitted && f.firstName?.errors" class="text-danger text-left" class="invalid-feedback">
                Your first name required
              </div>
            </div>
            <div class="form-group">
              <input type="text" class="form-control mt-0" placeholder="Last Name" name="lastName"
                id="lastNAme" formControlName="lastName"  [ngClass]="{'is-invalid': submitted && f.lastName?.errors }">

              <!-- <div *ngIf="contactForm.controls['lastName'].touched && contactForm.controls['lastName'].invalid"
                class="text-danger">
                <div *ngIf="contactForm.controls['lastName'].errors && contactForm.controls['lastName'].errors.required"
                  class="text-left">Last name is required.</div>
              </div> -->
              <div *ngIf="submitted && f.lastName?.errors" class="text-danger text-left" class="invalid-feedback">
                Your last name required
              </div>
            </div>
            <div class="form-group">
              <input type="tel" class="form-control" placeholder="Phone" name="phone" id="phone" 
                formControlName="phone"  [ngClass]="{'is-invalid': submitted && f.phone?.errors }" maxlength="10" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');"> 
<!-- 
              <div *ngIf="contactForm.controls['phone'].touched && contactForm.controls['phone'].invalid"
                class="text-danger text-left">
                <span *ngIf="contactForm.controls['phone'].errors && contactForm.controls['phone'].errors.required"
                  class="text-left">Phone Number is required</span>
                <p class="text-left" *ngIf="contactForm.controls['phone'].hasError('maxlength')">
                  Your phone cannot exceed 10 characters.
                </p>
              </div> -->
              <div *ngIf="submitted && f.phone?.errors" class="text-danger text-left" class="invalid-feedback">
                Please enter a valid mobile number
              </div>
            </div>
            <div class="form-group">
              <input type="email" class="form-control" placeholder="Email"  name="email" id="email"
                formControlName="email"  [ngClass]="{'is-invalid': submitted && f.email?.errors }">

              <!-- <div *ngIf="contactForm.controls['email'].touched && contactForm.controls['email'].invalid"
                class="text-danger text-left">
                <span *ngIf="contactForm.controls['email'].errors && contactForm.controls['email'].errors.required"
                  class="text-left">Email is required</span>
              </div> -->
              <div *ngIf="submitted && f.email?.errors" class="text-danger text-left" class="invalid-feedback">
                Please enter valid Email Id
              </div>
            </div>
            <div class="form-group">

              <input type="text" class="form-control" placeholder="Profession" name="profession-name"
                formControlName="profession_name" id="profession-name"  [ngClass]="{'is-invalid': submitted && f.profession_name?.errors }">
              <!--   
              <div
                *ngIf="contactForm.controls['profession_name'].touched && contactForm.controls['profession_name'].invalid"
                class="text-danger text-left">
                <span
                  *ngIf="contactForm.controls['profession_name'].errors && contactForm.controls['profession_name'].errors.required"
                  class="text-left">Please Enter Your Profession</span>
              </div> -->
              <div *ngIf="submitted && f.profession_name?.errors" class="text-danger text-left" class="invalid-feedback">
                Please enter your profession name
              </div>
            </div>
            <!-- <select class="form-control static-margin" name="profession-name" id="profession-name" required="" formControlName="profession_name" placeholder="profession-name">
                                <option value="" selected="" disabled=""> Profession</option>
                             </select> -->
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Company / Organzation" name="company_name"
                id="company_name"  formControlName="company_name"  [ngClass]="{'is-invalid': submitted && f.company_name?.errors }">
<!-- 
              <div *ngIf="contactForm.controls['company_name'].touched && contactForm.controls['company_name'].invalid"
                class="text-danger text-left">
                <span
                  *ngIf="contactForm.controls['company_name'].errors && contactForm.controls['company_name'].errors.required"
                  class="text-left">Enter Company Name</span>
              </div> -->
              <div *ngIf="submitted && f.company_name?.errors" class="text-danger text-left" class="invalid-feedback">
                Please enter your Organzation name
              </div>
            </div>

            <div class="form-group">
              <input type="text" class="form-control" placeholder="Designation" name="designation" id="designation"
                 formControlName="designation"  [ngClass]="{'is-invalid': submitted && f.designation?.errors }">
<!-- 
              <div *ngIf="contactForm.controls['designation'].touched && contactForm.controls['designation'].invalid"
                class="text-danger text-left">
                <span
                  *ngIf="contactForm.controls['designation'].errors && contactForm.controls['designation'].errors.required"
                  class="text-left">Please Enter Your Designation </span>
              </div> -->
              <div *ngIf="submitted && f.designation?.errors" class="text-danger text-left" class="invalid-feedback">
                Please enter your designation name
              </div>
            </div>

          </div>

          <div class="col-md-6">
            <div class="form-group">
              <!-- <input type="text" class="form-control textarea-address pb-0 pt-0" rows="4" placeholder="Address"
                name="address" id="address" formControlName="address" [ngClass]="{'is-invalid': submitted && f.address?.errors }"> -->

              <!-- <div *ngIf="contactForm.controls['address'].touched && contactForm.controls['address'].invalid"
                class="text-danger text-left">
                <span *ngIf="contactForm.controls['address'].errors && contactForm.controls['address'].errors.required"
                  class="text-left">Address is required</span>
              </div> -->

              <textarea rows="4" class="form-control" placeholder="Address"
              name="address" id="address" formControlName="address" [ngClass]="{'is-invalid': submitted && f.address?.errors }"></textarea>

              <div *ngIf="submitted && f.address?.errors" class="text-danger text-left" class="invalid-feedback">
                Please enter your address name
              </div>
            </div>
            <div class="form-group">
              <input type="text" class="form-control" placeholder="Country name" name="country" id="country"
                formControlName="country"  [ngClass]="{'is-invalid': submitted && f.country?.errors }">


             <!--  <div *ngIf="contactForm.controls['country'].touched && contactForm.controls['country'].invalid"
                class="text-danger text-left">
                <span *ngIf="contactForm.controls['country'].errors && contactForm.controls['country'].errors.required"
                  class="text-left">Country name is required</span>
              </div> -->
              <div *ngIf="submitted && f.country?.errors" class="text-danger text-left" class="invalid-feedback">
                Please enter your country name
              </div>
            </div>
            <!-- <select class="form-control" name="country" id="country" required="" formControlName="country">
                                <option value="">Select country</option>
                                
                             </select> -->
            <div class="form-group">
              <input type="text" class="form-control" name="city" id="city" required="" formControlName="city"
                placeholder="City name" [ngClass]="{'is-invalid': submitted && f.city?.errors }">

              <!-- <div *ngIf="contactForm.controls['city'].touched && contactForm.controls['city'].invalid"
                class="text-danger text-left">
                <span *ngIf="contactForm.controls['city'].errors && contactForm.controls['city'].errors.required"
                  class="text-left">City name is required</span>
              </div> -->
              <div *ngIf="submitted && f.city?.errors" class="text-danger text-left" class="invalid-feedback">
                Please enter your city name
              </div>
            </div>
           
            <div class="form-group">
              <textarea class="form-control textarea-comment static-margin pb-0" rows="4" placeholder="Comments"
                [ngClass]="{'is-invalid': submitted && f.comment?.errors }" name="comment" id="comment"
                formControlName="comment"></textarea>
              <div *ngIf="submitted && f.comment?.errors" class="text-danger text-left" class="invalid-feedback">
                Please enter your comment
              </div>
            </div>
          </div>

        </div>

        <div class="row mt-2" id="buttons">
          <div class="col-md-6 col-sm-6 col-xs-6 text-right" id="button1">
            <button type="button" id="clearbtn" class="btn btn-default btn-clear pl-3 pr-3"
            (click)="onReset()" >CLEAR</button>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-6 text-left" id="button2">
            <button type="submit" class="btn btn-default btn-clear pl-3 pr-3" data-toggle="modal" data-target="#exampleModal">SUBMIT</button>
          </div>
        </div>

      </form>
    </div>
  </div>
  <!---- modal popp up-->
  <div class="modal fade"  *ngIf="contactForm.valid"  #someModal id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="!submitted">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
         <div class="modal-body">
          <h5 class="modal-title" id="exampleModalLabel">Successfully Submitted.</h5>
          
        </div> 
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="onReset()">Ok</button>
        </div>
      </div>
    </div>
  </div>
<!-- modal pop up end -->

  <div class="row mt-5" id="add1" style="margin-bottom: 5px;">
    <div class="col-md-6  text-center" id="office1">
      <div class="contact-box">
        <img src="assets/img/contact-icon01.png" alt="contact icon">
        <h2>Corporate Office</h2>
        <p class="contact-grey-text">7721008844</p>
        <p class="contact-grey-text">S.No 41 B/2 Mouje Lohegaon, Lohegaon Pune Water Park Road, <br> Maharashtra 411047</p>
      </div>
    </div>
    <div class="col-md-6  text-center" id="office2">
      <div class="contact-box">
        <img src="assets/img/project-management-icon.png" alt="contact icon">
        <h2>E-mail Us</h2>
        <p class="contact-grey-text">Business Enquiry:  contact@avalondevelopers.com</p>
        <!-- <p class="contact-grey-text">Job Opportunities: careers@indulkar.com </p> -->
      </div>
    </div>

  </div><!-- row close -->
</section>

<app-footer></app-footer>
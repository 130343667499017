<!-- <nav class="navbar navbar-expand-sm bg-light fixed-top navbar-dark d-none d-lg-block d-xl-block">

  <button class="navbar-toggler navbar-dark bg-dark" type="button" data-toggle="collapse"
    data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
    <ul class="navbar-nav mx-auto mr-auto mt-2 mt-lg-0">
      <li class="nav-item active">
        <a class="nav-link cursor secondary" routerLink="/" id="navbardrop">
          Home
        </a>
      </li>

      <li class="nav-item dropdown" id="about">
        <a class="nav-link dropdown-toggle secondary" href="#" id="navbardrop" data-toggle="dropdown">
          About Us
        </a>
        <div class="dropdown-menu dropdown-menu-left" id="dd1">

          <ul class="ho1" id="ul1">
            <a class="dropdown-item">CORPORATE PROFILE</a>
            <li><a class="secondary" routerLink="/foreward">Forward</a></li>
            <li><a class="secondary" routerLink="/roadmap">Our Roadmap</a></li>
            <li><a class="secondary" routerLink="/profile">Company Profile</a></li>
            <li><a class="secondary" routerLink="/philosophy">Group Philosphy</a></li>
          </ul>

          <ul class="ho1" id="ul2">
            <a class="dropdown-item" routerLink="/leadershp">LEADERSHIP</a>
            <li class="my-2"><a class="secondary" routerLink="/leadershp/1">Anil Indulkar</a></li>
            <li class="my-2"><a class="secondary" routerLink="/leadershp/2">Gouri Indulkar</a></li>
            <li class="my-2"><a class="secondary" routerLink="/leadershp/3">Arjun Indulkar</a></li>
            <li class="my-2"><a class="secondary" routerLink="/leadershp/4">Raveena Indulkar</a></li>
            <li class="my-2"><a class="secondary" routerLink="/leadershp/5">Hitesh Patwa</a></li>
          </ul>

        </div>
      </li>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle secondary" href="#" id="navbardrop" data-toggle="dropdown">
          Alliances
        </a>
        <div class="dropdown-menu dropdown-menu-left" id="dd2">
          <ul class="ho2">
            <li class="d1"> <a class="dropdown-item" routerLink="/brand-assocate">BRAND ASSOCIATES</a></li>
            <li class="d2"> <a class="dropdown-item" routerLink="/partners">PARTNERS</a></li>
          </ul>
        </div>
      </li>

      <li class="nav-item">
        <a class="navbar-brand" href="#"><img src="assets/img/Logo - Avalon Relaty - 1920 X 1920.png"></a></li>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle secondary" href="#" id="navbardrop" data-toggle="dropdown">
          Career
        </a>
        <div class="dropdown-menu dropdown-menu-left" id="dd3">
          <ul class="ho2">
            <li class="o1"> <a class="dropdown-item" routerLink="/life">LIFE @ AVALON</a></li>
            <li class="o2"> <a class="dropdown-item" routerLink="/job-opportunity">JOB OPPORTUNITY</a></li>
          </ul>
        </div>
      </li>

      <li class="nav-item">
        <a class="nav-link secondary" routerLink="/media">Media</a>
      </li>

      <li class="nav-item">
        <a class="nav-link cursor secondary" id="navbardrop" routerLink="/contact">Contact Us</a>
      </li>

      <!-- <li class="nav-item">
        <button class="btn mt text-uppercase ">Buy Online</button>
      </li> 

    </ul>
  </div>
</nav> -->



<nav class="navbar navbar-expand-lg bg-light fixed-top navbar-dark 	d-none d-lg-block d-xl-block">

  <button class="navbar-toggler navbar-dark bg-dark mt-2 mb-2" type="button" data-toggle="collapse"
    data-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
    <ul class="navbar-nav mx-auto mr-auto mt-2 mt-lg-0">
      <li class="nav-item active">
        <a class="nav-link cursor secondary" routerLink="/" id="navbardrop">
          Home
        </a>
      </li>

      <li class="nav-item dropdown" id="about">
        <a class="nav-link dropdown-toggle secondary" href="#" id="navbardrop" data-toggle="dropdown">
          About Us
        </a>
        <div class="dropdown-menu dropdown-menu-left" id="dd1">

          <ul class="ho1" id="ul1">
            <a class="dropdown-item">CORPORATE PROFILE</a>
            <li><a class="secondary" routerLink="/foreward">Forward</a></li>
            <li><a class="secondary" routerLink="/roadmap">Our Roadmap</a></li>
            <li><a class="secondary" routerLink="/profile">Company Profile</a></li>
            <li><a class="secondary" routerLink="/philosophy">Group Philosphy</a></li>
          </ul>

          <ul class="ho1" id="ul2">
            <a class="dropdown-item" routerLink="/leadershp">LEADERSHIP</a>
            <li><a class="secondary" routerLink="/leadershp/1">Anil Indulkar</a></li>
            <li><a class="secondary" routerLink="/leadershp/2">Gouri Indulkar</a></li>
            <li><a class="secondary" routerLink="/leadershp/3">Arjun Indulkar</a></li>
            <li><a class="secondary" routerLink="/leadershp/4">Raveena Indulkar</a></li>
            <li><a class="secondary" routerLink="/leadershp/5">Hitesh Patwa</a></li>
          </ul>

        </div>
      </li>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle secondary" href="#" id="navbardrop" data-toggle="dropdown">
          Alliances
        </a>
        <div class="dropdown-menu dropdown-menu-left" id="dd2">
          <ul class="ho2">
            <li class="d1"> <a class="dropdown-item" routerLink="/brand-assocate">BRAND ASSOCIATES</a></li>
            <li class="d2"> <a class="dropdown-item" routerLink="/partners">PARTNERS</a></li>
          </ul>
        </div>
      </li>

      <li class="nav-item">
        <a class="navbar-brand" href="#"><img src="assets/img/Logo - Avalon Relaty - 1920 X 1920.png"></a></li>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle secondary" href="#" id="navbardrop" data-toggle="dropdown">
          Career
        </a>
        <div class="dropdown-menu dropdown-menu-left" id="dd3">
          <ul class="ho2">
            <li class="o1"> <a class="dropdown-item" routerLink="/life">LIFE @ AVALON</a></li>
            <!-- <li class="o2"> <a class="dropdown-item" routerLink="/job-opportunity">JOB OPPORTUNITY</a></li> -->
            <li class="o2"> <a class="dropdown-item" href = "https://indulkars.easyhrworld.com/outside/careers" target  =  "_blank">Apply Online</a></li>
          </ul>
        </div>
      </li>

      <li class="nav-item">
        <a class="nav-link secondary" routerLink="/media">Media</a>
      </li>

      <li class="nav-item">
        <a class="nav-link cursor secondary" id="navbardrop" routerLink="/contact">Contact Us</a>
      </li>

      <!-- <li class="nav-item">
        <button class="btn mt text-uppercase ">Buy Online</button>
      </li> -->

    </ul>
  </div>
</nav>

<nav class="navbar fixed-top  navbar-expand-lg bg-light  navbar-dark  d-lg-none d-xl-none d-md-block d-sm-block">
  <!-- Brand -->
    <a class="navbar-brand" href="#"><img src="assets/img/Logo - Avalon Relaty - 1920 X 1920.png"></a>

  <!-- Toggler/collapsibe Button -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar" style="margin-left: 52%;">
    <span class="line"></span> 
    <span class="line"></span> 
    <span class="line" style="margin-bottom: 0;"></span>
  </button>

  <!-- Navbar links -->
  <div class="collapse navbar-collapse" id="collapsibleNavbar">
    <ul class="navbar-nav mx-auto mr-auto mt-2 mt-lg-0">
      <li class="nav-item active">
        <a class="nav-link cursor secondary" routerLink="/" id="navbardrop">
          Home
        </a>
      </li>

      <li class="nav-item dropdown" id="about">
        <a class="nav-link dropdown-toggle secondary" href="#" id="navbardrop" data-toggle="dropdown">
          About Us
        </a>
        <div class="dropdown-menu dropdown-menu-left" id="dd1">

          <ul class="ho1" id="ul1">
            <a class="dropdown-item">CORPORATE PROFILE</a>
            <li><a class="secondary" routerLink="/foreward">Forward</a></li>
            <li><a class="secondary" routerLink="/roadmap">Our Roadmap</a></li>
            <li><a class="secondary" routerLink="/profile">Company Profile</a></li>
            <li><a class="secondary" routerLink="/philosophy">Group Philosphy</a></li>
          </ul>

          <ul class="ho1" id="ul2">
            <a class="dropdown-item" routerLink="/leadershp">LEADERSHIP</a>
            <li class="my-2"><a class="secondary" routerLink="/leadershp/1">Anil Indulkar</a></li>
            <li class="my-2"><a class="secondary" routerLink="/leadershp/2">Gouri Indulkar</a></li>
            <li class="my-2"><a class="secondary" routerLink="/leadershp/3">Arjun Indulkar</a></li>
            <li class="my-2"><a class="secondary" routerLink="/leadershp/4">Raveena Indulkar</a></li>
            <li class="my-2"><a class="secondary" routerLink="/leadershp/5">Hitesh Patwa</a></li>
          </ul>

        </div>
      </li>

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle secondary" href="#" id="navbardrop" data-toggle="dropdown">
          Alliances
        </a>
        <div class="dropdown-menu dropdown-menu-left" id="dd2">
          <ul class="ho2">
            <li class="d1"> <a class="dropdown-item" routerLink="/brand-assocate">BRAND ASSOCIATES</a></li>
            <li class="d2"> <a class="dropdown-item" routerLink="/partners">PARTNERS</a></li>
          </ul>
        </div>
      </li>

      <!-- <li class="nav-item">
        <a class="navbar-brand" href="#"><img src="assets/img/Logo - Avalon Relaty - 1920 X 1920.png"></a>
      </li> -->

      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle secondary" href="#" id="navbardrop" data-toggle="dropdown">
          Career
        </a>
        <div class="dropdown-menu dropdown-menu-left" id="dd3">
          <ul class="ho2">
            <li class="o1"> <a class="dropdown-item" routerLink="/life">LIFE @ AVALON</a></li>
            <li class="o2"> <a class="dropdown-item" routerLink="/job-opportunity">JOB OPPORTUNITY</a></li>
          </ul>
        </div>
      </li>

      <li class="nav-item">
        <a class="nav-link secondary" routerLink="/media">Media</a>
      </li>

      <li class="nav-item">
        <a class="nav-link cursor secondary" id="navbardrop" routerLink="/contact">Contact Us</a>
      </li>
    </ul>
  </div>
</nav>